import { useEffect } from "react";
import { formatDate, getCurrentChat } from "../shared/utils";
import { useChat } from "../chat/chat.hook";
import "./print.css";

export const PrintView = ({ uid }: any) => {
  const { chat } = useChat(uid);
  const messages = getCurrentChat(chat.chats, chat.currentChat);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <div className="print-view">
      {messages && (
        <div className="print-messaging-list">
          {messages.map((entry: any) => (
            <div
              key={entry.id}
              className={
                entry.myMessage ? "message-right-wrap" : "message-left-wrap"
              }
            >
              <div
                className={`message ${
                  entry.myMessage ? "message-right" : "message-left"
                }`}
              >
                <h3>From: {entry.myMessage ? "Me" : entry.currentChat}</h3>
                <span className="message-time">
                  {formatDate(entry.createdAt)}
                </span>
                {entry.message}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
