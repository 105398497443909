import { get, onValue, ref, remove, set } from "firebase/database";
import { useStore } from "../store";
import { firebaseDb } from "../firebase/firebase";
import { useMemo } from "react";

export enum PresetTabs {
  Stored = "stored",
  Sequence = "sequence",
}

export const usePresets = (uid: string) => {
  const { state, updateState } = useStore("presets", {
    user: [],
    presetTab: PresetTabs.Stored,
    messages: [
      { offset: 0, offsetMinutes: 0, date: "", message: "" },
      { offset: 1, offsetMinutes: 0, date: "", message: "" },
    ],
  });

  const updatePresets = (presetsMap: any) => {
    const userPresets = presetsToArray(presetsMap);

    const presets = {
      // default: !!data.default ? presetsToArray(data.default) : [],
      user: presetsMap["appointment"]
        ? userPresets
        : [
            {
              id: "appointment",
              name: "Next Appointment",
              type: "sequence",
              messages: [
                {
                  offset: 0,
                  offsetMinutes: 0,
                  date: new Date(),
                  message: `Your service appointment has been scheduled for {{date}}`,
                },
                {
                  offset: -7,
                  offsetMinutes: 0,
                  message: "Your service appointment is in a week",
                },
                {
                  offset: -1,
                  offsetMinutes: 0,
                  message: "Your service appointment is tomorrow",
                },
                {
                  offset: 0,
                  offsetMinutes: -60,
                  message: "Your service appointment is in 1 hour",
                },
              ],
            },
            ...userPresets,
          ],
    };

    updateState(presets);
  };

  const addPresetListener = (uid: string) => {
    onValue(ref(firebaseDb, `/presets/user/${uid}`), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        updatePresets(data);
      }
    });
  };

  const addId = (key: string, presets: any) => {
    if (typeof presets[key] === "object")
      return {
        id: key,
        name: presets[key].name,
        value: presets[key].message,
        type: presets[key].type,
        messages: presets[key].messages,
      };

    return { id: key, name: presets[key], value: presets[key] };
  };

  const presetsToArray = (presetMap: any) => {
    return Object.keys(presetMap)
      .map((key) => addId(key, presetMap))
      .sort((a, b) => (a.name < b.name ? -1 : 0));
  };

  const getPresets = () => {
    return get(ref(firebaseDb, "/presets")).then((snapshot) => {
      const data = snapshot.val();
      const presetsMap = data?.user?.[uid] ?? {};

      updatePresets(presetsMap);
      addPresetListener(uid);
    });
  };

  const addPreset = (name: string, message: string) => {
    const currentTime = new Date().getTime();
    const presetId = `${name.split(" ").join("").toLowerCase()}${currentTime}`;

    return set(ref(firebaseDb, `/presets/user/${uid}/${presetId}`), {
      id: presetId,
      name,
      message,
    });
  };

  const patchPreset = (preset: any, message: string) => {
    Object.keys(preset).forEach((key) => {
      if (preset[key] === undefined) delete preset[key];
    });

    return set(
      ref(firebaseDb, `/presets/user/${uid}/${preset.id}`),
      typeof preset === "object"
        ? {
            ...preset,
            message,
          }
        : message
    );
  };

  const removePreset = (presetId: string) => {
    return remove(ref(firebaseDb, `/presets/user/${uid}/${presetId}`));
  };

  const appointmentPreset = useMemo(
    () => state.user?.find((preset: any) => preset.id === "appointment"),
    [state.user]
  );

  return {
    presets: state,
    appointment: appointmentPreset,
    updatePresets: updateState,
    getPresets,
    addPreset,
    patchPreset,
    removePreset,
  };
};
