import { useEffect, useMemo } from "react";
import { ListSearch } from "../shared/list-search/list-search";
import { useChat } from "./chat.hook";
import {
  exportCsv,
  formatDate,
  formatNumber,
  getCurrentChat,
} from "../shared/utils";
import { useChatFilter } from "./chat-filter.hook";
import { Link } from "react-router-dom";
import { EditIcon, ExportIcon } from "../shared";
import { NewMessage } from "./new-message";
import Spinner from "../../assets/spinner.svg";
import { Presets } from "../presets/presets";
import "./chat.css";
import { useBroadcast } from "../broadcast/broadcast.hook";
import { MessageType } from "./shared/messgae-type";
import { AppointmentMessage } from "./appointment.ts/appointment";

export const ChatView = ({ uid }: any) => {
  const { chatFilter, updateChatFilter, sortAvailableChats } = useChatFilter();
  const { chat, updateChat, markAsRead } = useChat(uid);
  const { broadcast } = useBroadcast(uid);
  const { chats, currentChat } = chat;
  const { broadcasting } = broadcast;

  useEffect(() => {
    if (chatFilter.filterInactive) {
      updateChatFilter({
        filterInactive: false,
      });
    }
  }, []);

  const sortedChats = useMemo(
    () => sortAvailableChats(chats),
    [chats, chatFilter]
  );

  useEffect(() => {
    if (!currentChat && sortedChats.length) {
      updateChat({
        currentChat: sortedChats[0],
      });
    }
  }, [sortedChats]);

  const messages = useMemo(
    () => getCurrentChat(chats, currentChat),
    [chats, currentChat]
  );

  useEffect(() => {
    setTimeout(scrollToLatest, 0);
  }, [messages]);

  const scrollToLatest = () => {
    const listRef = document.getElementById("listRef");
    if (listRef) {
      listRef.scrollTo({ left: 0, top: listRef.scrollHeight });
    }
  };

  const getLastChecked = (number: string) =>
    (chats[number] && chats[number].lastChecked) || 0;

  const isUnread = (number: string) =>
    chats[number] && chats[number].lastUpdated > getLastChecked(number);

  const changeConvo = (number: string) => {
    updateChat({
      currentChat: number,
    });
    if (isUnread(number)) {
      markAsRead(number);
    }
  };

  return (
    <div>
      <div className="grid">
        <div className="app-sidebar">
          <ListSearch uid={uid} />
          <div className="number-list">
            {sortedChats.length === 0 && (
              <h2 className="text-center">You have no messages</h2>
            )}
            {sortedChats.map((number, index) => (
              <div
                onClick={() => changeConvo(number)}
                key={`${number}${index}`}
                className={`number-entry ${
                  currentChat === number ? "selected" : ""
                } ${chats[number].isSubscribed === false ? "inactive" : ""}`}
              >
                <div className="number-highlight">{formatNumber(number)}</div>
                {chats[number] && chats[number].firstName && (
                  <p>
                    {chats[number].firstName} {chats[number].lastName}{" "}
                    {chats[number].isSubscribed === false && "(INACTIVE)"}
                  </p>
                )}
                <button className="edit-number">
                  <Link to={`/edit/${number}`}>
                    <EditIcon />
                  </Link>
                </button>
                {/* {chats[number].isSubscribed && isUnread(number) && ( */}
                {isUnread(number) && <div className="new-messages"></div>}
              </div>
            ))}
          </div>
          <div className="list-tools">
            <button onClick={() => exportCsv(chats)} title="Export as CSV">
              <ExportIcon />
            </button>
          </div>
        </div>
        <div className="conversation">
          {messages && (
            <div id="listRef" className="messaging-list">
              {messages.map((convo: any) => (
                <div
                  key={convo.id}
                  className={
                    convo.myMessage ? "message-right-wrap" : "message-left-wrap"
                  }
                >
                  <div
                    className={`message ${
                      convo.myMessage ? "message-right" : "message-left"
                    }`}
                  >
                    <span className="message-time">
                      {formatDate(convo.createdAt)}
                    </span>
                    {convo.type === MessageType.Appointment ? (
                      <AppointmentMessage message={convo} />
                    ) : (
                      convo.message
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {messages && <NewMessage uid={uid} />}
        </div>
        <Presets uid={uid} />
        {broadcasting && (
          <div className="progressing">
            <h1>
              {broadcasting} <br /> <img alt="Loading..." src={Spinner} />
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};
