export const yearsOffset = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const currentYear = new Date().getUTCFullYear();
export const yearOptions = yearsOffset.map((offset) => currentYear - offset);
export const monthOptions = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
