import { useEffect, useMemo } from "react";
import { useStore } from "../store";
import { get, ref } from "firebase/database";
import { firebaseDb } from "../firebase/firebase";
import { defaultTimeZone } from "../admin/timezones";

let profileLoaded = false;

export const getCurrentCycle = (start: string) => {
  const now = new Date();
  const startDate = new Date(start);
  return (
    (now.getFullYear() - startDate.getFullYear()) * 12 +
    (now.getMonth() - startDate.getMonth())
  );
};

export const useProfile = (uid: string) => {
  const { state, updateState } = useStore("profile", { features: {} });
  const loading = useMemo(() => {
    return !state.phone;
  }, [state.phone]);

  useEffect(() => {
    if (!profileLoaded && uid) {
      profileLoaded = true;

      get(ref(firebaseDb, `numbers/${uid}`)).then((snapshot) => {
        const data = snapshot.val();

        updateState({
          phone: data,
        });
      });

      get(ref(firebaseDb, `messageStats/${uid}`)).then((snapshot) => {
        const data = snapshot.val();

        if (data) {
          const currentCycle = getCurrentCycle((data && data.cycleStart) || 0);

          updateState({
            cycle: currentCycle,
            limit: data.limit,
            contactsLimit: data.contactsLimit,
            storedLimit: data.storedLimit,
            sequenceLimit: data.sequenceLimit,
            tagsLimit: data.tagsLimit,
            sent:
              typeof data.sent === "object"
                ? data.sent[currentCycle] || 0
                : data.sent,
            features: data.features,
            timeZone: data.timeZone || defaultTimeZone,
          });
        }
      });
    }
  }, [uid]);

  return { loading, profile: state, updateProfile: updateState };
};
