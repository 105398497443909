export const AttachIcon = () => (
  <svg x="0px" y="0px" viewBox="0 0 50 50">
    <g>
      <path
        d="M12.5,6.2C9,6.2,6.2,9,6.2,12.5s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S16,6.2,12.5,6.2z M12.5,15.6c-1.7,0-3.1-1.4-3.1-3.1
        s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1S14.2,15.6,12.5,15.6z M43.8,0H6.2C2.8,0,0,2.8,0,6.2v37.5C0,47.2,2.8,50,6.2,50h37.5
        c3.5,0,6.2-2.8,6.2-6.2V6.2C50,2.8,47.2,0,43.8,0z M6.2,46.9c-1.7,0-3.1-1.4-3.1-3.1v-1.5l12.5-11l15.6,15.6H6.2z M46.9,43.8
        c0,1.7-1.4,3.1-3.1,3.1h-8.1L24.2,35.2l13.3-13.3l9.4,9.4V43.8z M46.9,26.8l-9.4-9.6L22,33l-6.3-6.4L3.1,38V6.2
        c0-1.7,1.4-3.1,3.1-3.1h37.5c1.7,0,3.1,1.4,3.1,3.1V26.8z"
      />
    </g>
  </svg>
);
