import { useEffect, useState } from "react";
import { useStore } from "../store";
import { firebaseDb } from "../firebase/firebase";
import { onValue, ref, remove, set, update } from "firebase/database";
import { useModal } from "../modal";
import { SEGMENT_LENGTH, getSegmentCount } from "../shared/utils";
import axios from "axios";
import { v4 } from "uuid";

export const SEND_MESSAGE_PATH =
  window.location.hostname === "localhost"
    ? "https://autoshophosting.com/senddev.php"
    : "https://autoshophosting.com/send.php";

let delaySound = false;
export const delayTheSound = (flag?: boolean) => {
  if (flag !== undefined) {
    delaySound = flag;
  } else {
    delaySound = true;
    setTimeout(() => {
      delaySound = false;
    }, 5000);
  }
};

const notificationSound = new Audio("notification.mp3");
let notifyApiAvailable = false;
if (!("Notification" in window)) {
  console.warn("This browser does not support desktop notification");
} else if (Notification.permission !== "denied") {
  Notification.requestPermission(function (permission: NotificationPermission) {
    if (permission === "granted") {
      notifyApiAvailable = true;
    }
  });
} else if ((Notification.permission as NotificationPermission) === "granted") {
  notifyApiAvailable = true;
}

let notification: any;
const clearNotification = () => {
  notification = undefined;
};
let triggerSound = false;
const listeners: any = {};

export const useChat = (uid: string, profile?: any, updateProfile?: any) => {
  const { state, updateState } = useStore("chat", {
    newMessage: "",
  });
  const [loading, setLoading] = useState(false);
  const { updateModal } = useModal();

  const addNewNumber = (
    number: string,
    firstName: string,
    lastName: string,
    make: string,
    tags: string
  ) => {
    setLoading(true);
    delayTheSound();

    const currentTime = new Date().getTime();
    return set(ref(firebaseDb, `/clients/${uid}/${number}`), {
      lastUpdated: currentTime,
      lastChecked: currentTime,
      firstName,
      lastName,
      make,
      tags,
      messages: {
        [`${currentTime}${number}`]: {
          id: `${currentTime}${number}`,
          createdAt: currentTime,
          message: `This is the start of your conversation with ${firstName} ${lastName} - ${number}`,
        },
      },
    }).finally(() => {
      setLoading(false);
    });
  };

  const removeNumber = (number: string) => {
    setLoading(true);
    delayTheSound();

    return remove(ref(firebaseDb, `/clients/${uid}/${number}`)).finally(() => {
      setLoading(false);
    });
  };

  const patchNumber = (
    number: string,
    firstName: string,
    lastName: string,
    make: string,
    tags: string
  ) => {
    setLoading(true);
    delayTheSound();

    const patchEntry: any = {};
    if (firstName) patchEntry.firstName = firstName;
    if (lastName) patchEntry.lastName = lastName;
    if (make) patchEntry.make = make;
    if (tags) patchEntry.tags = tags;

    return update(
      ref(firebaseDb, `/clients/${uid}/${number}`),
      patchEntry
    ).finally(() => {
      setLoading(false);
    });
  };

  const clearChat = (clientPhone: string) => {
    if (!clientPhone) return;

    updateModal({
      visible: true,
      title: "Clearing Conversation",
      content: `Are you sure you want to remove all messages for ${clientPhone}?`,
      action: async () => {
        const currentTime = new Date().getTime();
        delayTheSound();

        setLoading(true);

        try {
          await Promise.all([
            set(
              ref(firebaseDb, `/clients/${uid}/${clientPhone}/lastCleared`),
              currentTime
            ),
            set(ref(firebaseDb, `/clients/${uid}/${clientPhone}/messages`), {
              [currentTime]: {
                id: `${currentTime}${clientPhone}`,
                createdAt: currentTime,
                message: `This conversation was cleared on ${new Date().toLocaleDateString()}`,
              },
            }),
          ]);
        } catch (err) {
          console.error(err);
          alert("Failed to clear the conversation. Please try again.");
        }

        setLoading(false);
      },
    });
  };

  const listenChats = () => {
    if (!listeners[uid]) {
      listeners[uid] = true;

      onValue(ref(firebaseDb, `/clients/${uid}`), (snapshot) => {
        const data = snapshot.val();

        updateState({
          chats: data,
        });

        if (
          !delaySound &&
          triggerSound &&
          notificationSound &&
          notificationSound.play
        ) {
          try {
            notificationSound.play();

            if (notifyApiAvailable && !notification && !delaySound) {
              notification = new Notification(
                "GOMO: You have unread messages!",
                { requireInteraction: true }
              );
              notification.onclose = clearNotification;
            }
          } catch (ex) {
            console.warn("ex", ex);
          }
        }
        if (!triggerSound) triggerSound = true;

        const listRef = document.getElementById("listRef");
        if (listRef) {
          listRef.scrollTop = listRef.scrollHeight;
        }
      });
    }
  };

  useEffect(() => {
    if (uid) {
      listenChats();
    }
  }, [uid]);

  const markAsRead = (number: string) => {
    const currentTime = new Date().getTime();

    delayTheSound();

    return set(
      ref(firebaseDb, `/clients/${uid}/${number}/lastChecked`),
      currentTime
    );
  };

  const addMessageCount = (uid: string, message: string) => {
    const { sent, cycle } = profile;

    const newSentCount =
      message.length > SEGMENT_LENGTH
        ? sent + getSegmentCount(message)
        : sent + 1;

    updateProfile({
      sent: newSentCount,
    });

    set(
      ref(firebaseDb, `/messageStats/${uid}/sent/${cycle}`),
      newSentCount
    ).catch((err) => console.error(err));

    // const newSegmentCount = appState.auth.messageStats.segments + 1;
    // db.ref(`/messageStats/${uid}/segments/${appState.auth.messageStats.cycle}`).set(newSegmentCount)
    //   .then(() => {
    //     store.set('auth.messageStats.segments', newSegmentCount);
    //   });
  };

  const addNewMessage = (clientPhone: string, message: string) => {
    const { phone, sent, limit } = profile;

    const currentTime = new Date().getTime();

    updateState({
      laoding: true,
    });

    if (sent < limit) {
      delayTheSound(true);

      return Promise.all([
        axios.post(SEND_MESSAGE_PATH, {
          from: phone,
          to: clientPhone,
          message,
        }),

        set(
          ref(firebaseDb, `/clients/${uid}/${clientPhone}/lastUpdated`),
          currentTime
        ).catch((err) => console.error(err)),

        set(
          ref(firebaseDb, `/clients/${uid}/${clientPhone}/lastChecked`),
          currentTime
        ).catch((err) => console.error(err)),

        set(
          ref(
            firebaseDb,
            `/clients/${uid}/${clientPhone}/messages/${currentTime}${clientPhone}`
          ),
          {
            id: `${currentTime}${phone}`,
            myMessage: true,
            createdAt: currentTime,
            message,
          }
        )
          .then((resp) => {
            addMessageCount(uid, message);

            updateState({
              laoding: false,
            });
            delayTheSound(false);

            return resp;
          })
          .catch(() => {
            updateState({
              laoding: false,
            });
            delayTheSound(false);
          }),
      ]).catch((err) => {
        console.error(err);

        updateState({
          laoding: false,
        });

        delayTheSound(false);
      });
    } else {
      return Promise.reject("You have used all of your messaging quota.");
    }
  };

  const addNewAppointmentMessage = (clientPhone: string) => {
    const messageId = v4();
    const createdAt = new Date();

    const message = `New: Appointment reminder
Date: ${createdAt.toISOString()}
Time: 9:00am`;

    set(
      ref(firebaseDb, `/clients/${uid}/${clientPhone}/messages/${messageId}`),
      {
        id: messageId,
        createdAt: createdAt.getTime(),
        message,
        type: "appointment",
      }
    );
  };

  return {
    loading,
    chat: state,
    updateChat: updateState,
    addNewNumber,
    removeNumber,
    patchNumber,
    clearChat,
    markAsRead,
    addNewMessage,
    addNewAppointmentMessage,
  };
};
