export const getCurrentChat = (chats: any, currentChat: string) => {
  const messages =
    (chats &&
      chats[currentChat] &&
      Object.values(chats[currentChat].messages)) ||
    [];

  return messages.sort((a: any, b: any) => a.createdAt - b.createdAt);
};

export const SEGMENT_LENGTH = 160;

export const getSegmentCount = (message: string) => {
  return Math.ceil(message.length / SEGMENT_LENGTH);
};
