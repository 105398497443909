import { useStore } from "../store";

export const useChatFilter = () => {
  const { state, updateState } = useStore("chatFilter", {});

  const filterChats = (chats: Array<any>) => {
    const {
      filterYear,
      fitlerMonth,
      filterMake,
      filterTags,
      filterActive,
      filterInactive,
    } = state;
    const filterResults = state.filterResults?.toLocaleLowerCase();
    const filterExclude =
      state.filterExclude && state.filterExclude?.toLocaleLowerCase();

    if (!chats) return [];

    let convos = Object.entries(chats);
    if (filterYear || fitlerMonth) {
      convos = convos.filter(([_, convo]) => {
        const lastUpdated = new Date(convo.lastUpdated);
        const updatedYear = lastUpdated.getFullYear();
        const updatedMonth = lastUpdated.getMonth();
        if (
          filterYear &&
          fitlerMonth &&
          filterYear === `${updatedYear}` &&
          fitlerMonth === `${updatedMonth}`
        ) {
          return true;
        } else if (
          filterYear &&
          filterYear === `${updatedYear}` &&
          !fitlerMonth
        ) {
          return true;
        } else if (
          fitlerMonth &&
          fitlerMonth === `${updatedMonth}` &&
          !filterYear
        ) {
          return true;
        }
        return false;
      });
    }

    if (filterResults) {
      convos = convos.filter(([phone, convo]) => {
        const { firstName, lastName, make } = convo || {};
        let tags = convo?.tags || "";
        if (filterTags) {
          if (tags.toLocaleLowerCase().includes(filterResults)) return true;
          return false;
        }
        if (
          phone.toLocaleLowerCase().includes(filterResults) ||
          (firstName &&
            firstName.toLocaleLowerCase().includes(filterResults)) ||
          (lastName && lastName.toLocaleLowerCase().includes(filterResults)) ||
          (make && make.toLocaleLowerCase().includes(filterResults)) ||
          tags.toLocaleLowerCase().includes(filterResults)
        )
          return true;
        return false;
      });
    }

    if (filterMake) {
      convos = convos.filter(([_, convo]) => {
        const { make } = convo || {};
        if (make && make === filterMake) return true;
        return false;
      });
    }

    if (filterExclude) {
      convos = convos.filter(([phone, convo]) => {
        const { firstName, lastName, make } = convo || {};
        let tags = convo?.tags || "";
        if (filterTags) {
          if (tags.toLocaleLowerCase().includes(filterExclude)) return false;
          return true;
        }
        if (
          phone?.toLocaleLowerCase().includes(filterExclude) ||
          (firstName &&
            firstName?.toLocaleLowerCase().includes(filterExclude)) ||
          (lastName && lastName?.toLocaleLowerCase().includes(filterExclude)) ||
          (make && make?.toLocaleLowerCase().includes(filterExclude)) ||
          tags?.toLocaleLowerCase().includes(filterExclude)
        )
          return false;
        return true;
      });
    }

    if (filterActive) {
      convos = convos.filter(([_, convo]) => {
        const { isSubscribed } = convo || {};
        if (isSubscribed !== false) return true;
        return false;
      });
    }
    if (filterInactive) {
      convos = convos.filter(([_, convo]) => {
        const { isSubscribed } = convo || {};
        if (isSubscribed === false) return true;
        return false;
      });
    }

    return convos;
  };

  const getAvailableChats = (chats: Array<any>) => {
    return filterChats(chats).map(([phone]) => phone);
  };

  const getInactiveChats = (chats: Array<any>) => {
    return filterChats(chats)
      .filter((entry) => entry[1].isSubscribed === false)
      .map(([phone]) => phone);
  };

  const sortAvailableChats = (chats: Array<any>) => {
    if (chats) {
      const convos = filterChats(chats);
      const activeChats: Array<any> = [];
      const inactiveChats: Array<any> = [];

      convos.forEach((entry) => {
        if (entry[1].isSubscribed === false) {
          inactiveChats.push(entry);
        } else {
          activeChats.push(entry);
        }
      });

      return [
        ...activeChats
          .sort((a, b) => {
            return b[1].lastUpdated - a[1].lastUpdated;
          })
          .map(([phone]) => phone),
        ...inactiveChats.map(([phone]) => phone),
      ];
    }
    return [];
  };

  return {
    chatFilter: state,
    updateChatFilter: updateState,
    getAvailableChats,
    getInactiveChats,
    sortAvailableChats,
    filterChats,
  };
};
