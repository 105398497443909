import { NewMessage } from "../chat/new-message";
import { Presets } from "../presets/presets";
import { ListSearch } from "../shared/list-search/list-search";
import "./broadcast.css";
import { useBroadcast } from "./broadcast.hook";
import { CustomerTable } from "./customer-table";
import Spinner from "../../assets/spinner.svg";
import { useChat } from "../chat/chat.hook";
import { SEGMENT_LENGTH, getSegmentCount } from "../shared/utils";
import { useProfile } from "../profile";
import { replaceVariables } from "../shared/utils/text-utils";
import { ref, set } from "firebase/database";
import { firebaseDb } from "../firebase/firebase";
import { clearInputAndScheduled } from "../shared/utils/ui-utils";
import moment from "moment-timezone";

export const BroadcastView = ({ uid }: any) => {
  const { profile, updateProfile } = useProfile(uid);
  const { chat, updateChat } = useChat(uid);
  const { broadcast, updateBroadcast } = useBroadcast(uid);
  const { broadcasting, selectedRows } = broadcast;
  const { chats, newMessage, scheduleAt, scheduleTime } = chat;

  const scheduleBroadcast = () => {
    const { sent, limit, phone, cycle, timeZone } = profile;

    const selectedList = Object.keys(selectedRows).filter(
      (convoId) => !!selectedRows[convoId]
    );

    if (newMessage && selectedList.length) {
      const segmentCount = getSegmentCount(newMessage);
      const totalCount = segmentCount * selectedList.length;

      if (sent + totalCount < limit) {
        updateBroadcast({
          broadcasting: `Broadcast setup in progress. Do not close the browser.`,
        });

        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const date = currentDate.getDate();
        const today = new Date(
          `${currentDate.getFullYear()}-${month < 10 ? `0${month}` : month}-${
            date < 10 ? `0${date}` : date
          }`
        ).getTime();
        const broadcastAt = scheduleAt
          ? moment
              .tz(
                `${scheduleAt} ${scheduleTime ?? ""}`,
                scheduleTime ? "YYYY-MM-DD hh:mm a" : "YYYY-MM-DD",
                timeZone
              )
              .toDate()
              .getTime()
          : today;

        let sentCountUpdate = 0;
        const prepMsgs = selectedList.reduce((acc: any, currentConvo) => {
          const broadcastMsgId = `${phone}-${currentConvo}`.split("+").join("");
          const convoValues = chats[currentConvo];
          const ref = `/broadcasts/${broadcastAt}/${uid}/${broadcastMsgId}`;
          const message = replaceVariables(convoValues, newMessage);

          sentCountUpdate =
            message.length > SEGMENT_LENGTH
              ? sentCountUpdate + getSegmentCount(message)
              : sentCountUpdate + 1;

          acc[broadcastMsgId] = {
            id: broadcastMsgId,
            ref,
            createdAt: new Date().getTime(),
            scheduledAt: broadcastAt,
            from: phone,
            to: currentConvo,
            status: "pending",
            message: `${message} STOP=stop`,
          };

          return acc;
        }, {});

        set(ref(firebaseDb, `/broadcasts/${broadcastAt}/${uid}`), prepMsgs)
          .then(() => {
            setTimeout(() => {
              const newSentCount = sent + sentCountUpdate;
              updateProfile({
                sent: newSentCount,
              });

              set(
                ref(firebaseDb, `/messageStats/${uid}/sent/${cycle}`),
                newSentCount
              ).catch((err) => console.error(err));

              updateBroadcast({
                broadcasting: "",
                selectedRows: {},
              });

              updateChat({
                newMessage: "",
                scheduleAt: "",
                scheduleTime: "",
              });

              clearInputAndScheduled();
            }, 2000);
          })
          .catch((err) => {
            console.error("BROADCAST ERROR: ", err);
            setTimeout(() => {
              updateBroadcast({
                broadcasting: "",
              });
              alert("Failed to setup the broadcast");
            }, 2000);
          });
      } else {
        alert(
          "The broadcast is over your messaging limit. Please make sure you have enough messages to proceed."
        );
      }
    }
  };

  return (
    <div className="broadcast-view grid">
      <div className="broadcast-wrap">
        <div className="page-info">
          <h2 className="text-center">Group Broadcast</h2>
          <p className="text-center">
            Select the numbers you want to broadcast to, and use the form below
            to send the message.
          </p>
        </div>
        <ListSearch uid={uid} type="broadcast" />
        <CustomerTable uid={uid} />
        <NewMessage broadcastMode sendBroadcast={scheduleBroadcast} />
        {broadcasting && (
          <div className="progressing">
            <h1>
              {broadcasting} <br /> <img alt="Loading..." src={Spinner} />
            </h1>
          </div>
        )}
      </div>
      <Presets broadcastMode type="broadcast" uid={uid} />
    </div>
  );
};
