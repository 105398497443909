import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDcr9jlod6UewS6dMrRA1cojGRq22puUNQ",
  authDomain: "sms-web-client.firebaseapp.com",
  databaseURL: "https://sms-web-client.firebaseio.com",
  projectId: "sms-web-client",
  storageBucket: "sms-web-client.appspot.com",
  messagingSenderId: "953862992989",
  appId: "1:953862992989:web:267ead6acb3f664bab89b4",
  measurementId: "G-YDRRP9TD92",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firebaseDb = getDatabase(firebaseApp);
