export const exportCsv = (chats: Array<any>) => {
  const currentDate = new Date();
  const fileName = `export-${currentDate.getTime()}.csv`;
  const heading = `Cell,FirstName,LastName,Make,Tags\n`;
  const csvContent = Object.entries(chats)
    .map(([number, convo]) => {
      return `${number},${convo.firstName ? convo.firstName : ""},${
        convo.lastName ? convo.lastName : ""
      },${convo.make ? convo.make : ""},${
        convo.tags ? convo.tags.split(",").join(" |") : ""
      }`;
    })
    .join("\n");

  const blobContent = new Blob([heading, csvContent], {
    type: "application/csv;charset=utf-8;",
  });

  // @ts-ignore
  if (window.navigator.msSaveBlob) {
    // @ts-ignore
    window.navigator.msSaveBlob(blobContent, fileName);
  } else {
    const linkElement = document.createElement("a");
    const href = URL.createObjectURL(blobContent);
    linkElement.href = href;
    linkElement.download = fileName;
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  }
};
