import { useState } from "react";
import Spinner from "../../assets/spinner.svg";
import { useAuth } from "./auth.hook";
import "./auth.css";

export const AuthView = () => {
  const { processLogin, authError, loading } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (event: any) => {
    event.preventDefault();
    processLogin(email, password);
  };

  return (
    <div className="grid">
      <form onSubmit={onSubmit} className="login-screen">
        <h1 className="text-center">
          Login to GO-MO
          <br />
          Messaging Dashboard
        </h1>
        {authError && <p className="error">{authError}</p>}
        <p>
          <input
            onChange={(event: any) => setEmail(event.target.value)}
            name="email"
            className="input"
            placeholder="Email"
          />
        </p>
        <p>
          <input
            onChange={(event: any) => setPassword(event.target.value)}
            name="password"
            className="input"
            placeholder="Password"
            type="password"
          />
        </p>
        <p>
          <button className="button button-large" type="submit">
            {!loading && "Login"}
            {loading && <img alt="Loading..." src={Spinner} />}
          </button>
        </p>
      </form>
    </div>
  );
};
