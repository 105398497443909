import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../assets/spinner.svg";
import "./new-message.css";
import { useChat } from "../chat.hook";
import { useUi } from "../../ui";
import { useBroadcast } from "../../broadcast/broadcast.hook";
import {
  scheduleSequence,
  scheduleSingle,
} from "../../schedule/schedule.service";
import { exportCsv, getSegmentCount } from "../../shared/utils";
import { useProfile } from "../../profile";
import { replaceVariables } from "../../shared/utils/text-utils";
import {
  AttachIcon,
  CancelIcon,
  ExportIcon,
  PrintIcon,
  ScheduleIcon,
} from "../../shared";
import { clearInputAndScheduled } from "../../shared/utils/ui-utils";
import { usePresets } from "../../presets/presets.hook";

export const NewMessage = ({ uid, broadcastMode, sendBroadcast }: any) => {
  const { ui, updateUi } = useUi();
  const { profile, updateProfile } = useProfile(uid);
  const { chat, updateChat, clearChat, addNewMessage } = useChat(
    uid,
    profile,
    updateProfile
  );
  const { presets, updatePresets } = usePresets(uid);
  const [showLengthWarning, setShowLengthWarning] = useState(false);

  useEffect(() => {
    updatePresets({
      currentPreset: "",
    });

    return () => {
      updateChat({
        newMessage: "",
      });
    };
  }, []);

  const toggleAttach = () => {
    if (ui.showAttachInput) {
      updateChat({
        attachLink: "",
      });
    }
    updateUi({
      showAttachInput: !ui.showAttachInput,
    });
  };

  const toggleSchedule = () => {
    if (ui.showScheduleInput) {
      updateChat({
        scheduleAt: "",
        scheduleTime: "",
      });
    }
    updateUi({
      showScheduleInput: !ui.showScheduleInput,
    });
  };

  const dateChange = (event: any) => {
    updateChat({
      scheduleAt: event.target.value,
    });
  };

  const timeChange = (event: any) => {
    updateChat({
      scheduleTime: event.target.value,
    });
  };

  const inputChange = (event: any) => {
    if (broadcastMode) {
      if (event.target.value.length > 160) {
        const shortened = event.target.value.slice(0, 160);

        updateChat({
          newMessage: shortened,
        });
        setShowLengthWarning(true);

        event.target.value = shortened;
        return;
      } else if (showLengthWarning) {
        setShowLengthWarning(false);
      }
    }

    updateChat({
      newMessage: event.target.value,
    });
  };

  const createNewMessage = () => {
    const { chats, scheduleAt, currentChat, newMessage } = chat;
    const { currentPreset } = presets;
    const { sent, limit } = profile;

    updateChat({
      loading: true,
    });

    // Sequence
    if (currentPreset) {
      return scheduleSequence(currentPreset.messages).finally(() => {
        updateChat({
          loading: false,
        });
      });
    }

    // Schedule single
    if (scheduleAt && currentChat && newMessage) {
      scheduleSingle(newMessage);
    } else if (newMessage) {
      // Send single
      const messageSegments = getSegmentCount(newMessage);
      if (sent + messageSegments < limit) {
        addNewMessage(
          currentChat,
          replaceVariables(chats[currentChat], newMessage)
        )
          .catch((err) => {
            console.warn("err", err);
          })
          .finally(() => {
            updateChat({
              loading: false,
            });
          });

        updateChat({
          newMessage: "",
          scheduleAt: "",
          scheduleTime: "",
        });

        clearInputAndScheduled();
      } else {
        alert("You don't have enough available messages to proceed.");
      }
    }
  };

  const {
    loading,
    chats,
    currentChat,
    scheduleAt,
    scheduleTime,
    attachImage,
    newMessage,
    selectedChats,
  } = chat;
  const { features } = profile;
  const { currentPreset } = presets;

  const selectedChatsLength = selectedChats
    ? Object.values(selectedChats).filter((chat) => !!chat).length
    : 0;

  return (
    <div className="send-message">
      <div className="message-tools">
        {broadcastMode && (
          <button
            onClick={() => exportCsv(chats)}
            title="Export as CSV"
            className="right-widget print-btn export-btn"
          >
            <ExportIcon />
          </button>
        )}
        {!broadcastMode && (
          <React.Fragment>
            <button
              onClick={() => clearChat(currentChat)}
              className="danger-btn"
            >
              <CancelIcon /> Clear All
            </button>
            <Link to="/print">
              <button
                title="Open print friendly view"
                className="right-widget print-btn"
              >
                <PrintIcon />
              </button>
            </Link>
          </React.Fragment>
        )}
        {features && features.schedule && (
          <button
            onClick={toggleSchedule}
            title="Schedule message"
            className="right-widget print-btn"
          >
            <ScheduleIcon />
          </button>
        )}
        {ui.showScheduleInput && (
          <>
            <input
              onChange={timeChange}
              defaultValue={scheduleTime}
              id="scheduleTime"
              type="time"
              className="right-widget base-input"
              disabled={!scheduleAt}
              title={
                !scheduleAt
                  ? "Select date before picking time"
                  : "Pick time to send"
              }
            />
            <input
              onChange={dateChange}
              defaultValue={scheduleAt}
              id="scheduleAt"
              type="date"
              className="right-widget base-input"
            />
          </>
        )}
        {features && features.mms && (
          <button
            onClick={toggleAttach}
            title="Attach an image"
            className="right-widget print-btn"
          >
            <AttachIcon />
          </button>
        )}
        {ui.showAttachInput && (
          <input
            defaultValue={attachImage}
            id="attachImage"
            placeholder="https://link-to-your-image"
            className="right-widget base-input"
          />
        )}
      </div>
      {/* {!!Fields && (
        <div className="message-tools">
          <Fields />
        </div>
      )} */}
      <div className="input-area">
        <textarea
          disabled={!!currentPreset}
          tabIndex={0}
          id="inputBox"
          onInput={inputChange}
          className="textarea"
          value={newMessage}
        />
      </div>
      <div className="action-area">
        {!currentPreset && (
          <div className="character-count text-right">
            {newMessage.length} / 160
            <div
              title="If message length is more that 160 characters it will be counted as multiple messages."
              className="segment-count"
            >
              {Math.ceil(newMessage.length / 160) *
                (broadcastMode ? selectedChatsLength : 1)}
            </div>
          </div>
        )}

        <button
          tabIndex={0}
          onClick={
            !loading && !showLengthWarning
              ? broadcastMode
                ? sendBroadcast
                : createNewMessage
              : null
          }
          className="button"
          disabled={
            showLengthWarning || (broadcastMode && newMessage.length > 160)
          }
        >
          {!loading && (!!scheduleAt || !!currentPreset ? "Schedule" : "Send")}
          {loading && <img alt="Loading..." src={Spinner} />}
        </button>
      </div>
      {(showLengthWarning || (broadcastMode && newMessage.length > 160)) && (
        <p className="error-msg">
          WARNING! Broadcast must be under 160 characters or it will be flagged
          as spam.
        </p>
      )}
    </div>
  );
};
