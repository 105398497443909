export const makeOptions = [
  "ACURA",
  "ALFA ROMEO",
  "ASTON MARTIN",
  "AUDI",
  "BMW",
  "BENTLEY",
  "BUICK",
  "CADILLAC",
  "CHEVROLET",
  "CHRYSLER",
  "DODGE",
  "FERRARI",
  "FIAT",
  "FORD",
  "GMC",
  "GENESIS",
  "HONDA",
  "HYUNDAI",
  "INFINITI",
  "ISUZU",
  "JAGUAR",
  "JEEP",
  "KARMA",
  "KIA",
  "LAMBORGHINI",
  "LAND ROVER",
  "LEXUS",
  "LINCOLN",
  "MASERATI",
  "MAZDA",
  "MCLAREN",
  "MERCEDES-BENZ",
  "MINI",
  "MITSUBISHI",
  "NISSAN",
  "PORSCHE",
  "RAM",
  "ROLLS ROYCE",
  "SMART",
  "SUBARU",
  "SUZUKI",
  "TESLA",
  "TOYOTA",
  "VOLKSWAGEN",
  "VOLVO",
  "MERCURY",
  "OLDSMOBILE",
  "PONTIAC",
  "SATURN",
  "SAAB",
  "ADMIN",
];
