const cachedValues: any = {};

export const formatNumber = (number: string) => {
  if (cachedValues[number]) return cachedValues[number];

  const areaCode = number.slice(2, 5);
  const part1 = number.slice(5, 8);
  const part2 = number.slice(8, number.length);
  cachedValues[number] = `(${areaCode}) ${part1}-${part2}`;

  return cachedValues[number];
};

export const fixPhoneNumber = (number: string) => {
  let phoneNumber = number.trim();
  phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
  if (phoneNumber[0] === "1") {
    phoneNumber = `+${phoneNumber}`;
  } else {
    phoneNumber = `+1${phoneNumber}`;
  }
  return phoneNumber;
};
