import { useState } from "react";
import { useStore } from "../store";
import {
  browserSessionPersistence,
  initializeAuth,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseApp, firebaseDb } from "../firebase/firebase";
import "./auth.css";
import { onValue, ref } from "firebase/database";

export const SESSION_KEY = "psmss";
export const PROFILE_KEY = "gomo_prfl";

const userSession = sessionStorage.getItem(SESSION_KEY);
const profileOverride = sessionStorage.getItem(PROFILE_KEY);
const currentVersion = "1.0.0";

export const useAuth = () => {
  const firebaseAuth = initializeAuth(firebaseApp, {
    persistence: [browserSessionPersistence],
  });
  const { state, updateState } = useStore("auth", {
    user: profileOverride
      ? { uid: profileOverride }
      : userSession
      ? JSON.parse(userSession)
      : null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const listenForReload = () => {
    if (state.user) {
      const appReloadedRef = ref(firebaseDb, "appVersion");
      onValue(appReloadedRef, (snapshot) => {
        const data = snapshot.val();

        if (data?.version && currentVersion !== data.version) {
          if (data?.forceLogout) {
            sessionStorage.setItem(SESSION_KEY, "");
            sessionStorage.removeItem(SESSION_KEY);
          }

          window.location.reload();
        }
      });
    }
  };

  const processLogin = (email: string, pass: string) => {
    setLoading(true);
    return signInWithEmailAndPassword(firebaseAuth, email, pass)
      .then((res) => {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(res.user));

        updateState({
          user: res.user,
        });

        listenForReload();

        return res.user;
      })
      .catch((res) => {
        console.error(res);
        if (
          res.code === "auth/invalid-email" ||
          res.code === "auth/wrong-password" ||
          res.code === "auth/user-not-found"
        ) {
          setError("Invalid email or password.");
        } else {
          setError("Server Error.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const processLogout = () => {
    sessionStorage.setItem(SESSION_KEY, "");
    sessionStorage.removeItem(SESSION_KEY);
    sessionStorage.removeItem(PROFILE_KEY);
    return signOut(firebaseAuth).then(() => {
      window.location.href = "/";
    });
  };

  return {
    loading,
    auth: state,
    authError: error,
    updateAuth: updateState,
    processLogin,
    processLogout,
  };
};
