import { useNavigate } from "react-router-dom";
import "./sequence.css";
import { usePresets } from "./presets.hook";
import { v4 } from "uuid";
import { ref, set } from "firebase/database";
import { firebaseDb } from "../firebase/firebase";
import { CancelIcon } from "../shared";
import { useEffect } from "react";

export const SequenceView = ({ uid }: any) => {
  const navigate = useNavigate();
  const { presets, updatePresets } = usePresets(uid);
  const { currentPreset, sequenceId, sequenceName, messages } = presets;

  useEffect(() => {
    if (currentPreset) {
      updatePresets({
        sequenceId: currentPreset.id,
        sequenceName: currentPreset.name,
        messages: currentPreset.messages,
      });
    }
  }, []);

  const startDateChange = (event: any) => {
    const { messages } = presets;
    const newMessageList = messages.slice(1, messages.length);
    updatePresets({
      messages: [
        { ...messages[0], date: event.target.value },
        ...newMessageList,
      ],
    });
  };

  const nameChange = (event: any) => {
    if (!event.target.value) return;
    updatePresets({ sequenceName: event.target.value });
  };

  const offsetChange = (index: number, event: any) => {
    const { messages } = presets;
    messages[index] = {
      ...messages[index],
      offset: parseInt(event.target.value, 10),
    };
    updatePresets({ messages });
  };

  const offsetMinutesChange = (index: number, event: any) => {
    const { messages } = presets;
    messages[index] = {
      ...messages[index],
      offsetMinutes: parseInt(event.target.value, 10),
    };
    updatePresets({ messages });
  };

  const messageChange = (index: number, event: any) => {
    const { messages } = presets;
    messages[index] = { ...messages[index], message: event.target.value };
    updatePresets({ messages });
  };

  const resetSequence = () => {
    updatePresets({
      messages: [
        { offset: 0, offsetMinutes: 0, date: "", message: "" },
        { offset: 1, offsetMinutes: 0, date: "", message: "" },
      ],
    });
  };

  const addNewMessage = () => {
    const { messages } = presets;
    updatePresets({
      messages: [
        ...messages,
        { offset: 1, offsetMinutes: 0, date: "", message: "" },
      ],
    });
  };

  const removeMessage = (event: any) => {
    const { messages } = presets;
    const { index } = event.currentTarget.dataset;
    const filteredItems = messages.filter((_: any, i: number) => {
      return i !== parseInt(index, 10);
    });
    updatePresets({
      messages: [...filteredItems],
    });
  };

  const validateSequence = () => {
    const { messages, sequenceName } = presets;
    return messages.reduce((acc: any, item: any) => {
      if (!sequenceName) return false;
      if (!item.message) return false;
      return acc;
    }, true);
  };

  const saveSequence = () => {
    const { sequenceId, sequenceName, messages } = presets;
    set(ref(firebaseDb, `/presets/user/${uid}/${sequenceId ?? v4()}`), {
      name: sequenceName,
      messages: messages,
      type: "sequence",
    }).then(() => {
      updatePresets({
        currentPreset: null,
      });
      navigate("/");
    });
  };

  return (
    <div className="grid">
      <div className="sequence-wrap">
        <div className="page-info">
          <h2 className="text-center">
            {sequenceId ? "Edit" : "New"} Sequence
          </h2>
          <p className="text-center">
            You can setup a sequence of messages with offset in days.
          </p>
        </div>
        <div className="page-content">
          {messages.map((item: any, index: number) => (
            <div key={`sms-card-${index}`} className="sms-card">
              {index !== 0 && (
                <button
                  data-index={index}
                  onClick={removeMessage}
                  className="cancel-top-btn"
                >
                  <CancelIcon />
                </button>
              )}
              {index == 0 && (
                <span key={sequenceName} className="input-label">
                  Sequence Name
                  <input onChange={nameChange} defaultValue={sequenceName} />
                </span>
              )}
              <span className="input-label">
                Offset from scheduled date
                <div className="grid">
                  <div className="half-section">
                    In days:
                    <input
                      key={`msg-${index}-${sequenceId}-offset`}
                      onChange={(event) => offsetChange(index, event)}
                      type="number"
                      defaultValue={item.offset}
                    />
                  </div>
                  <div className="half-section">
                    In minutes:
                    <input
                      key={`msg-${index}-${sequenceId}-offset-minutes`}
                      onChange={(event) => offsetMinutesChange(index, event)}
                      type="number"
                      defaultValue={item.offsetMinutes}
                    />
                  </div>
                </div>
              </span>
              <span className="input-label">Message</span>
              <textarea
                key={`msg-${index}-${sequenceId}`}
                onChange={(event) => messageChange(index, event)}
                defaultValue={item.message}
              />
            </div>
          ))}
          <button onClick={addNewMessage} className="add-btn">
            + Add
          </button>
        </div>
        <div className="submit-section">
          <button
            className="button submit-btn"
            disabled={!validateSequence()}
            onClick={saveSequence}
          >
            Save sequence
          </button>
        </div>
      </div>
    </div>
  );
};
