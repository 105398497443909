import Spinner from "../../assets/spinner.svg";
import "./admin.css";
import { useAdmin } from "./admin.hook";
import { useNavigate } from "react-router-dom";
import { defaultTimeZone, timeZoneList } from "./timezones";

export const UsersView = () => {
  const navigate = useNavigate();
  const { admin, updateAdmin, addProfile } = useAdmin();
  const { loading, adminError } = admin;
  const fields: { [key: string]: any } = {};

  const fieldChange = (event: any) => {
    if (adminError) {
      updateAdmin({
        adminError: "",
      });
    }
    fields[event.target.name] = event.target.value;
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    const { email, password, phone } = fields;
    if (email && password && phone) {
      addProfile(fields).then(() => {
        navigate("/success");
      });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="user-form">
        <h1 className="text-center">Add new user</h1>
        {adminError && <p className="error">{adminError}</p>}

        <div className="col-4">
          <p>
            <input
              onChange={fieldChange}
              name="email"
              className="input"
              placeholder="Email"
            />
          </p>
          <p>
            <input
              onChange={fieldChange}
              name="password"
              className="input"
              placeholder="Password"
            />
          </p>
          <p>
            <input
              onChange={fieldChange}
              name="phone"
              className="input"
              placeholder="Twilio number (ex: +17165015006)"
            />
          </p>
        </div>
        <div className="col-4">
          <p>
            <select
              onChange={fieldChange}
              name="timeZone"
              className="input"
              placeholder="Time zone"
            >
              {timeZoneList.map((item) => (
                <option selected={item === defaultTimeZone} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </p>
          <p>
            <input
              onChange={fieldChange}
              name="notifyPhoneList"
              className="input"
              placeholder="Notify (ex: +17165015006,+7164032112)"
            />
          </p>
          <p>
            <button className="button button-large" type="submit">
              {!loading && "Create user"}
              {loading && <img alt="Loading..." src={Spinner} />}
            </button>
          </p>
        </div>
      </form>
    </div>
  );
};
