import { useMemo } from "react";
import { useChat } from "../chat/chat.hook";
import { useBroadcast } from "./broadcast.hook";
import { useChatFilter } from "../chat/chat-filter.hook";
import { Link } from "react-router-dom";
import { EditIcon } from "../shared";
import { formatNumber } from "../shared/utils";
import "./customer-table.css";

export const CustomerTable = ({ uid }: any) => {
  const { chat } = useChat(uid);
  const { chatFilter, getAvailableChats, getInactiveChats } = useChatFilter();
  const { broadcast, updateBroadcast } = useBroadcast(uid);
  const { selectedRows, rowHovered } = broadcast;
  const { chats } = chat;
  const currentChats = useMemo(
    () => getAvailableChats(chats),
    [chats, chatFilter]
  );

  const toggleAll = () => {
    const selectedLength = Object.keys(selectedRows).length;

    if (selectedLength === currentChats.length) {
      updateBroadcast({
        selectedRows: {},
      });
    } else {
      updateBroadcast({
        selectedRows: currentChats.reduce((acc: any, chat: string) => {
          acc[chat] = true;
          return acc;
        }, {}),
      });
    }
  };

  const selectChat = (rowId: string) => {
    updateBroadcast({
      selectedRows: {
        ...selectedRows,
        [rowId]: !selectedRows[rowId],
      },
    });
  };

  const onRowHover = (rowId: string) => {
    updateBroadcast({
      rowHovered: rowId,
    });
  };

  const clearHovered = () => {
    updateBroadcast({
      rowHovered: "",
    });
  };

  return (
    <div>
      <table className="app-table no-padding" cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td width="5%" className="app-table-header">
              <input onChange={() => toggleAll()} type="checkbox" />
            </td>
            <td width="20%" className="app-table-header">
              <strong>CUSTOMER NUMBER</strong>
            </td>
            <td width="20%" className="app-table-header">
              <strong>NAME</strong>
            </td>
            <td width="15%" className="app-table-header">
              <strong>MAKE</strong>
            </td>
            <td width="15%" className="app-table-header">
              <strong>TAGS</strong>
            </td>
            <td width="10%" className="app-table-header">
              <strong>ACTIVE</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="scrolling-table">
        <table className="app-table" cellPadding="0" cellSpacing="0">
          <tbody>
            {currentChats.map((number, index) => {
              const customer = chats[number];

              return (
                <tr
                  onMouseOver={() => onRowHover(number)}
                  onMouseOut={clearHovered}
                  key={`${number}${index}`}
                  className={customer?.isSubscribed === false ? "inactive" : ""}
                >
                  <td width="5%">
                    <button
                      className={`${
                        rowHovered === number ? "row-hover " : ""
                      }tiny-tool-btn edit first`}
                    >
                      <Link to={`/edit/${number}`}>
                        <EditIcon />
                      </Link>
                    </button>
                    <input
                      checked={!!selectedRows[number]}
                      onChange={() => selectChat(number)}
                      type="checkbox"
                    />
                  </td>
                  <td width="20%" onClick={() => selectChat(number)}>
                    {formatNumber(number)}
                  </td>
                  <td width="20%">
                    {`${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}
                  </td>
                  <td width="15%">{customer?.make ?? ""}</td>
                  <td width="15%">{customer?.tags ?? ""}</td>
                  <td width="10%">
                    {customer?.isSubscribed === undefined
                      ? ""
                      : customer.isSubscribed
                      ? "YES"
                      : "NO"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
