import { useEffect, useMemo } from "react";
import Spinner from "../../assets/spinner.svg";
import "./admin.css";
import { useAdmin } from "./admin.hook";
import { useNavigate } from "react-router-dom";
import { useModal } from "../modal";
import { fixPhoneNumber, formatNumber } from "../shared/utils";
import { ref, set } from "firebase/database";
import { firebaseDb } from "../firebase/firebase";
import { CancelIcon } from "../shared";
import { PROFILE_KEY } from "../auth";

export const AdminView = () => {
  const navigate = useNavigate();
  const {
    admin,
    updateAdmin,
    getProfiles,
    setMessageLimit,
    setContactsLimit,
    setStoredLimit,
    setSequenceLimit,
    setTagsLimit,
    removeProfile,
    toggleFeature,
    setNotifyPhoneList,
  } = useAdmin();
  const { updateModal } = useModal();
  const {
    rowHovered,
    profiles,
    csvUploading,
    filterResults,
    messageStats,
    numRevMap,
  } = admin;

  const profileList = useMemo(() => {
    if (profiles && messageStats && numRevMap) {
      return Object.values(profiles)
        .filter((profile: any) => {
          return (
            !profile.delete &&
            (!filterResults ||
              profile.phone.includes(filterResults) ||
              profile.email.includes(filterResults))
          );
        })
        .map(({ email, phone }: any) => {
          const uid = numRevMap[phone];
          return {
            uid,
            email,
            phone,
            messageStats: messageStats[uid] || {},
          };
        });
    }
    return [];
  }, [profiles, filterResults]);

  useEffect(() => {
    getProfiles();
  }, []);

  const onFilterResults = (event: any) => {
    updateAdmin({
      filterResults: event.target.value,
    });
  };

  const changeLimit = (event: any, uid: string) => {
    const newLimit = event.target.value;
    setMessageLimit(uid, newLimit).then(() => {
      getProfiles();
    });
  };

  const changeContactsLimit = (event: any, uid: string) => {
    const newLimit = parseInt(event.target.value);
    setContactsLimit(uid, newLimit).then(() => {
      getProfiles();
    });
  };

  const changeStoredLimit = (event: any, uid: string) => {
    const newLimit = parseInt(event.target.value);
    setStoredLimit(uid, newLimit).then(() => {
      getProfiles();
    });
  };

  const changeSequenceLimit = (event: any, uid: string) => {
    const newLimit = parseInt(event.target.value);
    setSequenceLimit(uid, newLimit).then(() => {
      getProfiles();
    });
  };

  const changeTagsLimit = (event: any, uid: string) => {
    const newLimit = parseInt(event.target.value);
    setTagsLimit(uid, newLimit).then(() => {
      getProfiles();
    });
  };

  const changeNotifyPhoneList = (event: any, uid: string) => {
    const newList = event.target.value;
    setNotifyPhoneList(uid, newList).then(() => {
      getProfiles();
    });
  };

  const onRemoveProfile = (phone: string) => {
    updateModal({
      visible: true,
      title: "Removing Profile",
      content: `Are you sure you want to remove ${phone}?`,
      action: () => {
        removeProfile(phone).then(() => {
          getProfiles();
        });
      },
    });
  };

  const onImpersonate = (phone: string) => {
    sessionStorage.setItem(PROFILE_KEY, numRevMap[phone]);
    navigate("/");
    window.location.reload();
  };

  const onRowHovered = (rowId: string) => {
    updateAdmin({
      rowHovered: rowId,
    });
  };

  const clearHovered = () => {
    updateAdmin({
      rowHovered: "",
    });
  };

  const toggleBroadcastFeature = (profile: any) => {
    const { numRevMap } = admin;
    const uid = numRevMap[profile.phone];
    const flag = !(
      profile.messageStats.features && profile.messageStats.features.broadcast
    );

    toggleFeature(uid, "broadcast", flag).then(() => {
      getProfiles();
    });
  };

  const toggleScheduleFeature = (profile: any) => {
    const { numRevMap } = admin;
    const uid = numRevMap[profile.phone];
    const flag = !(
      profile.messageStats.features && profile.messageStats.features.schedule
    );

    toggleFeature(uid, "schedule", flag).then(() => {
      getProfiles();
    });
  };

  const toggleSequenceFeature = (profile: any) => {
    const { numRevMap } = admin;
    const uid = numRevMap[profile.phone];
    const flag = !(
      profile.messageStats.features && profile.messageStats.features.sequence
    );

    toggleFeature(uid, "sequence", flag).then(() => {
      getProfiles();
    });
  };

  const toggleVariablesFeature = (profile: any) => {
    const { numRevMap } = admin;
    const uid = numRevMap[profile.phone];
    const flag = !(
      profile.messageStats.features && profile.messageStats.features.variables
    );

    toggleFeature(uid, "variables", flag).then(() => {
      getProfiles();
    });
  };

  const getLatestSentCount = (sent: any) => {
    if (!sent) return 0;

    let latestKey = 0;
    Object.keys(sent).forEach((key) => {
      const parsedKey = parseInt(key, 10);
      if (parsedKey > latestKey) {
        latestKey = parsedKey;
      }
    });

    return sent[latestKey] || 0;
  };

  const fileUploaded = (event: any, profile: any) => {
    const { numRevMap } = admin;
    const reader = new FileReader();
    const tempStore = document.getElementById("temp");
    if (!tempStore) return;

    const fileInput = event.target;
    const userId = numRevMap[profile.phone.trim()];

    reader.onload = function () {
      tempStore.innerHTML = reader.result as string;
      const dataList = tempStore.innerHTML.split("\n");
      const columns = dataList[0].split(",");

      let firstNameIndex: number;
      let lastNameIndex: number;
      let cellIndex: number;
      let makeIndex: number;
      let tagsIndex: number;
      let failedImports = 0;

      columns.forEach((col, index) => {
        if (col === "FirstName" || col === "First_Name") firstNameIndex = index;
        if (col === "LastName" || col === "Last_Name") lastNameIndex = index;
        if (col === "Cell" || col === "Cell_Phone") cellIndex = index;
        if (col === "Make") makeIndex = index;
        if (col === "Tags") tagsIndex = index;
      });

      dataList.forEach((row, rowIndex) => {
        const rowCols = row.split(",");
        if (rowIndex !== 0 && rowCols.length === columns.length) {
          const createdAt = new Date().getTime();
          const formattedPhone = fixPhoneNumber(rowCols[cellIndex]);
          const entry = {
            isSubscribed: false,
            lastUpdated: createdAt,
            firstName: rowCols[firstNameIndex],
            lastName: rowCols[lastNameIndex],
            make: rowCols[makeIndex] ? rowCols[makeIndex].toUpperCase() : "",
            tags: rowCols[tagsIndex] ? rowCols[tagsIndex] : "",
            messages: {
              firstMessage: {
                id: "firstMessage",
                message: `This is the start of your conversation with ${formattedPhone}`,
                createdAt,
              },
            },
          };

          setTimeout(() => {
            updateAdmin({
              csvUploading: `Upload in progress. Do not close the browser. Uploading ${
                rowIndex + 1
              } of ${dataList.length}.`,
            });

            console.warn("path: ", `clients/${userId}/${formattedPhone}`);
            console.warn("entry: ", entry);

            set(
              ref(firebaseDb, `clients/${userId}/${formattedPhone}`),
              entry
            ).then(() => {
              if (rowIndex === dataList.length - 1) {
                updateAdmin({
                  csvUploading: "",
                });
                fileInput.value = "";
              }
            });
          }, rowIndex * 100);
        } else {
          if (rowIndex !== 0) {
            failedImports++;
          }

          setTimeout(() => {
            updateAdmin({
              csvUploading: `Upload in progress. Do not close the browser. Uploading ${
                rowIndex + 1
              } of ${dataList.length}.${
                failedImports
                  ? ` Failed to import ${failedImports} entries.`
                  : ""
              }`,
            });
            if (rowIndex === dataList.length - 1) {
              updateAdmin({
                csvUploading: "",
              });
              fileInput.value = "";
            }
          }, rowIndex * 100);
        }
      });
    };
    reader.readAsBinaryString(event.target.files[0]);
  };

  return (
    <div>
      <div className="top-right-radius user-table">
        <div className="top-right-radius search-input-wrap">
          <input
            onInput={onFilterResults}
            className="base-input"
            placeholder="Search"
          />
        </div>
        <table
          className="admin-table app-table"
          cellPadding="0"
          cellSpacing="0"
          key={profileList.length ? "show-list" : "no-list"}
        >
          <tbody>
            <tr>
              <td width="20%" className="app-table-header">
                <strong>USER</strong>
              </td>
              <td width="5%" className="app-table-header">
                <strong>BROADCAST</strong>
              </td>
              <td width="5%" className="app-table-header">
                <strong>SCHEDULE</strong>
              </td>
              <td width="5%" className="app-table-header">
                <strong>SEQUENCE</strong>
              </td>
              <td width="5%" className="app-table-header">
                <strong>VARIABLES</strong>
              </td>
              {/* <td width="10%" className="app-table-header"><strong>SEGMENTS</strong></td> */}
              <td width="5%" className="app-table-header">
                <strong>SENT</strong>
              </td>
              <td width="1%" className="app-table-header">
                <strong>MESSAGE LIMIT</strong>
              </td>
              <td width="10%" className="app-table-header">
                <strong>CONTACTS LIMIT</strong>
              </td>
              <td width="10%" className="app-table-header">
                <strong>STORED LIMIT</strong>
              </td>
              <td width="10%" className="app-table-header">
                <strong>SEQUENCE LIMIT</strong>
              </td>
              <td width="10%" className="app-table-header">
                <strong>TAGS LIMIT</strong>
              </td>
              <td width="10%" className="app-table-header">
                <strong>CSV upload</strong>
              </td>
            </tr>
            {profileList.map((profile: any, index: number) => (
              <tr
                onMouseOver={() => onRowHovered(profile.phone)}
                onMouseOut={clearHovered}
                key={`${profile.phone}${index}`}
              >
                <td width="20%">
                  <button
                    onClick={() => onRemoveProfile(profile.phone)}
                    className={`${
                      rowHovered === profile.phone ? "row-hover " : ""
                    }tiny-tool-btn first`}
                  >
                    <CancelIcon />
                  </button>
                  <button
                    onClick={() => onImpersonate(profile.phone)}
                    className={`${
                      rowHovered === profile.phone ? "row-hover " : ""
                    }imp-btn`}
                    title={`Impersonate ${profile.phone}`}
                  >
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                  <div style={{ overflow: "auto", maxWidth: "200px" }}>
                    {profile.email}
                    <br />
                    {formatNumber(profile.phone)}
                    <input
                      onBlur={(event) =>
                        changeNotifyPhoneList(event, profile.uid)
                      }
                      defaultValue={profile.messageStats.notifyPhoneList}
                      placeholder="Notify (ex: +17165015006,+7164032112)"
                      name="notifyPhoneList"
                    />
                  </div>
                </td>
                <td width="5%" className="text-center">
                  <input
                    onChange={() => toggleBroadcastFeature(profile)}
                    defaultChecked={profile.messageStats?.features?.broadcast}
                    type="checkbox"
                  />
                </td>
                <td width="5%" className="text-center">
                  <input
                    onChange={() => toggleScheduleFeature(profile)}
                    defaultChecked={profile.messageStats?.features?.schedule}
                    type="checkbox"
                  />
                </td>
                <td width="5%" className="text-center">
                  <input
                    onChange={() => toggleSequenceFeature(profile)}
                    defaultChecked={profile.messageStats?.features?.sequence}
                    type="checkbox"
                  />
                </td>
                <td width="5%" className="text-center">
                  <input
                    onChange={() => toggleVariablesFeature(profile)}
                    defaultChecked={profile.messageStats?.features?.variables}
                    type="checkbox"
                  />
                </td>
                {/* <td width="10%" className="text-center">
                    {this.getLatestSentCount(profile.messageStats.segment)}
                  </td> */}
                <td width="5%" className="text-center">
                  {getLatestSentCount(profile.messageStats.sent)}
                </td>
                <td width="7%" className="no-padding">
                  <input
                    onBlur={(event) => changeLimit(event, profile.uid)}
                    defaultValue={profile.messageStats.limit}
                    name="limit"
                    className="limit-input"
                  />
                </td>
                <td width="7%" className="no-padding">
                  <input
                    onBlur={(event) => changeContactsLimit(event, profile.uid)}
                    defaultValue={profile.messageStats.contactsLimit || 0}
                    name="contacts-limit"
                    className="limit-input"
                  />
                </td>
                <td width="7%" className="no-padding">
                  <input
                    onBlur={(event) => changeStoredLimit(event, profile.uid)}
                    defaultValue={profile.messageStats.storedLimit || 0}
                    name="stored-limit"
                    className="limit-input"
                  />
                </td>
                <td width="7%" className="no-padding">
                  <input
                    onBlur={(event) => changeSequenceLimit(event, profile.uid)}
                    defaultValue={profile.messageStats.sequenceLimit || 0}
                    name="sequence-limit"
                    className="limit-input"
                  />
                </td>
                <td width="7%" className="no-padding">
                  <input
                    onBlur={(event) => changeTagsLimit(event, profile.uid)}
                    defaultValue={profile.messageStats.tagsLimit || 0}
                    name="tags-limit"
                    className="limit-input"
                  />
                </td>
                <td width="15%" className="text-center no-padding">
                  <input
                    onChange={(event) => fileUploaded(event, profile)}
                    type="file"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {csvUploading && (
        <div className="progressing">
          <h1>
            {csvUploading} <br /> <img alt="Loading..." src={Spinner} />
          </h1>
        </div>
      )}
      <div style={{ display: "none" }} id="temp"></div>
    </div>
  );
};
