export const ScheduleIcon = () => (
  <svg x="0px" y="0px" viewBox="0 0 50 50">
    <g>
      <path
        d="M23.4,43.8h3.1v-3.1h-3.1V43.8z M25,0C11.2,0,0,11.2,0,25c0,13.8,11.2,25,25,25s25-11.2,25-25C50,11.2,38.8,0,25,0z
        M25,46.9C12.9,46.9,3.1,37.1,3.1,25C3.1,12.9,12.9,3.1,25,3.1S46.9,12.9,46.9,25C46.9,37.1,37.1,46.9,25,46.9z M15.1,13l-2.2-2.2
        L10.7,13l2.2,2.2L15.1,13z M25,6.3c-0.9,0-1.6,0.7-1.6,1.6v15.7H10.9c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6H25
        c0.9,0,1.6-0.7,1.6-1.6V7.8C26.6,6.9,25.9,6.3,25,6.3z M10.5,35.7l2.2,2.2l2.2-2.2l-2.2-2.2L10.5,35.7z M34,35.6l2.2,2.2l2.2-2.2
        l-2.2-2.2L34,35.6z M40.6,23.4v3.1h3.1v-3.1H40.6z M34.5,12.9l2.2,2.2l2.2-2.2l-2.2-2.2L34.5,12.9z"
      />
    </g>
  </svg>
);
