import { Route, Routes } from "react-router-dom";
import { TopNav } from "../shared/top-nav/top-nav";
import { ModalView } from "../modal";
import { AuthView, useAuth } from "../auth";
import { useProfile } from "../profile";
import Spinner from "../../assets/spinner.svg";
import { ContactView } from "../contact/contact.view";
import { ChatView } from "../chat/chat.view";
import { SequenceView } from "../presets/sequence.view";
import { BroadcastView } from "../broadcast/broadcast.view";
import { StatusView } from "../status/status.view";
import { AdminView } from "../admin/admin.view";
import { UsersView } from "../admin/users.view";

export const AppView = () => {
  const { auth } = useAuth();
  const { loading, profile } = useProfile(auth.user?.uid);

  return (
    <>
      <TopNav isPublic={!auth?.user} profile={profile} />
      <section className="app-wrapper">
        {auth.user ? (
          <Routes>
            <Route path="/add" element={<ContactView uid={auth.user?.uid} />} />
            <Route
              path="/sequence"
              element={<SequenceView uid={auth.user?.uid} />}
            />
            <Route
              path="/broadcast"
              element={<BroadcastView uid={auth.user?.uid} />}
            />
            <Route
              path="/status"
              element={<StatusView uid={auth.user?.uid} />}
            />
            <Route path="/" element={<ChatView uid={auth.user?.uid} />} />
            <Route
              path="/edit/:phone"
              element={<ContactView uid={auth.user?.uid} />}
            />
            <Route path="/admin" element={<AdminView />} />
            <Route path="/users" element={<UsersView />} />
          </Routes>
        ) : (
          <AuthView />
        )}
      </section>
      <ModalView />
      {auth.user && loading && (
        <div className="popup-window-bg">
          <img alt="Loading..." src={Spinner} />
        </div>
      )}
    </>
  );
};
