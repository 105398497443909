export const ExportIcon = () => (
  <svg x="0px" y="0px" viewBox="0 0 50 50">
    <g>
      <path
        id="Path_2_"
        display="inline"
        d="M26.3,11.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2v19.1l-4.1-5
        c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8l6.2,7.5l0,0c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,1-0.4l6.2-7.5
        c0.5-0.5,0.5-1.3,0-1.8c-0.5-0.5-1.3-0.5-1.8,0l-4.1,4.9"
      />
      <path
        id="Path"
        display="inline"
        d="M36.2,37.5H13.8c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2h22.5c0.7,0,1.2-0.6,1.2-1.2
        C37.5,38.1,36.9,37.5,36.2,37.5L36.2,37.5z"
      />
    </g>
  </svg>
);
