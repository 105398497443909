import { useStore } from "../store";

export enum StatusTabs {
  Scheduled = "scheduled",
  Broadcast = "broadcast",
}

export const useStatus = () => {
  const { state, updateState } = useStore("status", {
    currentTab: StatusTabs.Broadcast,
  });
  return { status: state, updateStatus: updateState };
};
