import { globalAppState } from "../../store";

export const toSentenceCase = (text: string) => {
  const trimmed = text.trim();
  return `${trimmed.slice(0, 1)}${trimmed
    .slice(1, trimmed.length)
    .toLowerCase()}`;
};

export const replaceVariables = (conversation: any, newMessage: any) => {
  if (
    !globalAppState.profile.features ||
    (globalAppState.profile.features &&
      !globalAppState.profile.features.variables)
  )
    return newMessage;
  let message = newMessage;

  if (conversation.firstName) {
    message = message
      .split("{first_name}")
      .join(toSentenceCase(conversation.firstName));
  } else message = message.split("{first_name}").join("Hi");

  if (conversation.lastName) {
    message = message
      .split("{last_name}")
      .join(toSentenceCase(conversation.lastName));
  } else message = message.split("{last_name}").join("");

  if (conversation.make) {
    message = message.split("{make}").join(toSentenceCase(conversation.make));
  } else message = message.split("{make}").join("vehicle");

  return message;
};
