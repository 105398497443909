import { useModal } from "./modal.hook";

export const ModalView = () => {
  const { modal, updateModal } = useModal();

  const acceptPrompt = () => {
    try {
      modal?.action();
    } finally {
      updateModal({ visible: false });
    }
  };

  return (
    <>
      {modal && modal.visible && (
        <section className="popup-window-bg">
          <div className="popup-window">
            <div>
              <h1>{modal.title}</h1>
              <p>{modal.content}</p>
            </div>
            <button
              onClick={() => updateModal({ visible: false })}
              className="popup-btn"
            >
              Cancel
            </button>
            <button onClick={acceptPrompt} className="popup-btn float-right">
              Proceed
            </button>
          </div>
        </section>
      )}
    </>
  );
};
