import { useChatFilter } from "../../chat/chat-filter.hook";
import { useChat } from "../../chat/chat.hook";
import { makeOptions } from "../../contact/make-options";
import { monthOptions, yearOptions } from "../utils/date-utils";

export const ListSearch = ({ uid, type }: any) => {
  const { chat } = useChat(uid);
  const { chatFilter, updateChatFilter, getAvailableChats } = useChatFilter();

  const getAllResultsLength = (chats: Array<any>) => {
    if (!chats) return 0;
    return Object.entries(chats).length;
  };

  const currentConvos = getAvailableChats(chat.chats);
  const total = getAllResultsLength(chat.chats);

  const filterResults = (event: any) => {
    updateChatFilter({ filterResults: event.target.value });
  };

  const changeFilterExclude = (event: any) => {
    updateChatFilter({ filterExclude: event.target.value });
  };

  const filterYear = (event: any) => {
    updateChatFilter({ filterYear: event.target.value });
  };

  const filterMonth = (event: any) => {
    updateChatFilter({ fitlerMonth: event.target.value });
  };

  const filterActive = () => {
    updateChatFilter({
      filterActive: !chatFilter.filterActive,
      filterInactive: false,
    });
  };

  const filterInactive = () => {
    updateChatFilter({
      filterInactive: !chatFilter.filterInactive,
      filterActive: false,
    });
  };

  const filterTags = () => {
    updateChatFilter({ filterTags: !chatFilter.filterTags });
  };

  const filterMake = (event: any) => {
    updateChatFilter({ filterMake: event.target.value });
  };

  return (
    <div className="search-input-wrap">
      <select onChange={filterYear} className="search-select">
        <option value="">Year</option>
        {yearOptions.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <select onChange={filterMonth} className="search-select">
        <option value="">Month</option>
        {monthOptions.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      {type === "broadcast" && (
        <>
          <select onChange={filterMake} className="search-select">
            <option value="">Make</option>
            {makeOptions.map((make) => (
              <option key={make} value={make}>
                {make}
              </option>
            ))}
          </select>
          <span>
            <input
              checked={chatFilter.filterActive}
              onChange={filterActive}
              type="checkbox"
            />
            Active
          </span>
          <span>
            <input
              checked={chatFilter.filterInactive}
              onChange={filterInactive}
              type="checkbox"
            />
            Inactive
          </span>
          <br />
        </>
      )}
      <input
        onInput={filterResults}
        className="base-input"
        placeholder="Search"
      />
      {type === "broadcast" && (
        <input
          onInput={changeFilterExclude}
          className="base-input"
          placeholder="Exclude from search"
        />
      )}
      <span>
        <input
          checked={chatFilter.filterTags}
          onChange={filterTags}
          type="checkbox"
        />
        Filter by tags
      </span>
      <div className="results-number">
        Resutls: {currentConvos.length} of {total}
      </div>
    </div>
  );
};
