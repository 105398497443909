import { Dispatch, SetStateAction } from "react";
import { v4 } from "uuid";

export const globalAppState: any = {};
// @ts-ignore
window.globalAppState = globalAppState;

export class StoreService {
  private nameSpace = "";
  private subsMap: any = {};
  private subs: Array<Dispatch<SetStateAction<any>>> = [];
  state = {};

  constructor(newNameSpace: string, defaultState: { [key: string]: any } = {}) {
    this.nameSpace = newNameSpace;
    globalAppState[this.nameSpace] = defaultState;
    this.state = globalAppState[this.nameSpace];
  }

  subscribe = (setState: Dispatch<SetStateAction<any>>): string => {
    const subId = v4();
    this.subsMap[subId] = setState;
    this.subs.push(setState);
    return subId;
  };

  unsubscribe = (subId: string) => {
    const currentSub = this.subsMap[subId];
    this.subs.splice(this.subs.indexOf(currentSub), 1);
    delete this.subsMap[subId];
  };

  setState = (newState: any) => {
    globalAppState[this.nameSpace] = newState;
    this.state = globalAppState[this.nameSpace];
    this.subs.forEach((setSubState) => {
      setSubState(this.state);
    });
  };

  updateState = (partialState: any) => {
    this.setState({
      ...globalAppState[this.nameSpace],
      ...partialState,
    });
  };
}
