import { StatusTabs, useStatus } from "./status.hook";
import { useModal } from "../modal";
import { useBroadcast } from "../broadcast/broadcast.hook";
import { removeSequenceMsg } from "../schedule/schedule.service";
import { CancelIcon } from "../shared";
import { formatNumber } from "../shared/utils";
import "./status.css";
import { useEffect } from "react";

export const formatDate = (timestamp: any) => {
  const newDate = new Date(timestamp);
  if (newDate.getUTCHours() || newDate.getUTCMinutes())
    return `${newDate.toString().split("00:00:00")[0]}`;
  return `${newDate.toUTCString().split("00:00:00")[0]}`;
};

export const StatusView = ({ uid }: any) => {
  const { updateModal } = useModal();
  const { broadcast, fetchBroadcasts } = useBroadcast(uid);
  const { status, updateStatus } = useStatus();
  const { currentTab, rowHovered, filterResults } = status;
  const { myBroadcasts } = broadcast;

  useEffect(() => {
    fetchBroadcasts();
  }, []);

  const onFilterResults = (event: any) => {
    updateStatus({
      filterResults: event.target.value,
    });
  };

  const filterItems = (items: any) => {
    return items.filter((item: any) => {
      return (
        item.to.includes(filterResults) || item.message.includes(filterResults)
      );
    });
  };

  let scheduledItems = myBroadcasts.filter(
    (broadcast: any) => broadcast.type === "schedule"
  );
  if (filterResults) scheduledItems = filterItems(scheduledItems);

  let broadcastItems = myBroadcasts.filter(
    (broadcast: any) => broadcast.type !== "schedule"
  );
  if (filterResults) broadcastItems = filterItems(broadcastItems);

  const onRowHover = (rowId: string) => {
    updateStatus({
      rowHovered: rowId,
    });
  };

  const clearHovered = () => {
    updateStatus({
      rowHovered: "",
    });
  };

  const removeItem = (item: any) => {
    updateModal({
      visible: true,
      title: "Removing pending message",
      content: `Are you sure you want to remove: (${item.to}) ${item.message}?`,
      action: () => {
        removeSequenceMsg(item).then(() => {
          fetchBroadcasts();
        });
      },
    });
  };

  let displayItems: Array<any> = [];
  const currentItems =
    currentTab === StatusTabs.Scheduled ? scheduledItems : broadcastItems;
  const byGroups = currentItems.reduce((acc: any, entry: any) => {
    if (entry.groupId) {
      if (!acc[entry.groupId]) acc[entry.groupId] = [];

      acc[entry.groupId].push(entry);
    } else {
      displayItems.push(entry);
    }
    return acc;
  }, {});
  const groupList: Array<any> = Object.entries(byGroups);

  const removeGroup = (list: Array<any>) => {
    updateModal({
      visible: true,
      title: "Removing pending messages",
      content: `Are you sure you want to proceed?`,
      action: () => {
        Promise.all(
          list.map((broadcast: any) => {
            removeSequenceMsg(broadcast);
          })
        ).then(() => {
          fetchBroadcasts();
        });
      },
    });
  };

  return (
    <div className="grid">
      <div className="schedule-wrap">
        <div className="page-info">
          <p className="text-center">
            <button
              onClick={() =>
                updateStatus({
                  currentTab: StatusTabs.Broadcast,
                })
              }
              className={`type-switch ${
                currentTab === StatusTabs.Broadcast ? "active" : ""
              }`}
            >
              Broadcast
            </button>
            |
            <button
              onClick={() =>
                updateStatus({
                  currentTab: StatusTabs.Scheduled,
                })
              }
              className={`type-switch ${
                currentTab === StatusTabs.Scheduled ? "active" : ""
              }`}
            >
              Scheduled / Sequence
            </button>
          </p>
          {currentTab === StatusTabs.Scheduled && (
            <>
              <h2 className="text-center">Scheduled status</h2>
              <p className="text-center">
                You can review and monitor your scheduled and sequence messages.
              </p>
            </>
          )}
          {currentTab === StatusTabs.Broadcast && (
            <>
              <h2 className="text-center">Broadcast status</h2>
              <p className="text-center">
                You can review and monitor your pending broadcasts.
              </p>
            </>
          )}
        </div>
        <table className="app-table" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td colSpan={4} style={{ padding: 0 }}>
                <div className="search-input-wrap">
                  <input
                    onInput={onFilterResults}
                    className="base-input"
                    placeholder="Search by phone #"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="app-table-header">
                <strong>DATE</strong>
              </td>
              <td className="app-table-header">
                <strong>STATUS</strong>
              </td>
              <td className="app-table-header">
                <strong>TO</strong>
              </td>
              <td className="app-table-header">
                <strong>MESSAGE</strong>
              </td>
            </tr>
            {((currentTab === StatusTabs.Scheduled && !scheduledItems.length) ||
              (currentTab === StatusTabs.Broadcast &&
                !broadcastItems.length)) && (
              <tr>
                <td className="text-center" colSpan={4}>
                  No pending messages
                </td>
              </tr>
            )}
            {displayItems.map((broadcast: any) => (
              <tr
                key={`${broadcast.scheduledAt}-${broadcast.id}`}
                onMouseOver={() => onRowHover(broadcast.ref)}
                onMouseOut={clearHovered}
              >
                <td>
                  <button
                    onClick={() => removeItem(broadcast)}
                    className={`${
                      rowHovered === broadcast.ref ? "row-hover " : ""
                    }tiny-tool-btn first`}
                  >
                    <CancelIcon />
                  </button>
                  {formatDate(broadcast.scheduledAt)}
                </td>
                <td>Pending</td>
                <td>{formatNumber(broadcast.to)}</td>
                <td>{broadcast.message}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {groupList.map(([groupId, list]) => (
          <table className="app-table" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td colSpan={4} style={{ padding: 0 }}>
                  <div className="search-input-wrap">
                    <button
                      onClick={() => removeGroup(list)}
                      className={`row-hover tiny-tool-btn`}
                    >
                      <CancelIcon />
                    </button>
                    {currentTab === StatusTabs.Scheduled
                      ? " Sequence "
                      : " Broadcast "}{" "}
                    ({groupId})
                  </div>
                </td>
              </tr>
              <tr>
                <td className="app-table-header">
                  <strong>DATE</strong>
                </td>
                <td className="app-table-header">
                  <strong>STATUS</strong>
                </td>
                <td className="app-table-header">
                  <strong>TO</strong>
                </td>
                <td className="app-table-header">
                  <strong>MESSAGE</strong>
                </td>
              </tr>
              {list.map((broadcast: any) => (
                <tr
                  key={`${broadcast.scheduledAt}-${broadcast.id}`}
                  onMouseOver={() => onRowHover(broadcast.ref)}
                  onMouseOut={clearHovered}
                >
                  <td>
                    <button
                      onClick={() => removeItem(broadcast)}
                      className={`${
                        rowHovered === broadcast.ref ? "row-hover " : ""
                      }tiny-tool-btn first`}
                    >
                      <CancelIcon />
                    </button>
                    {formatDate(broadcast.scheduledAt)}
                  </td>
                  <td>Pending</td>
                  <td>{formatNumber(broadcast.to)}</td>
                  <td>{broadcast.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};
