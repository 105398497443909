import { useEffect, useState } from "react";
import { StoreService } from "../store/store.service";

export const namedStore: { [key: string]: StoreService } = {};

export const useStore = (nameSpace: string, defaultState: any) => {
  const [state, setState] = useState<any>(
    namedStore[nameSpace]?.state ?? defaultState
  );

  useEffect(() => {
    if (!namedStore[nameSpace])
      namedStore[nameSpace] = new StoreService(nameSpace, defaultState);

    const subId = namedStore[nameSpace].subscribe(setState);
    return () => {
      namedStore[nameSpace].unsubscribe(subId);
    };
  }, []);

  const updateState = (partialState: any) => {
    namedStore[nameSpace].updateState({
      ...namedStore[nameSpace].state,
      ...partialState,
    });
  };

  const setStoreState = (state: any) => {
    namedStore[nameSpace].setState(state);
  };

  return {
    state,
    updateState,
    setState: setStoreState,
  };
};
