import { useStore } from "../store";

export interface Modal {
  visible: boolean;
  title: string;
  content: string;
  action: () => void;
}

export const useModal = (): {
  modal?: Modal;
  updateModal: (partialState: any) => void;
  setModal: any;
} => {
  const { state, updateState, setState } = useStore("modal", {});

  return { modal: state, updateModal: updateState, setModal: setState };
};
