import { get, ref } from "firebase/database";
import { useStore } from "../store";
import { firebaseDb } from "../firebase/firebase";

export const useBroadcast = (uid: string) => {
  const { state, updateState } = useStore("broadcast", {
    selectedRows: {},
    myBroadcasts: [],
  });

  const fetchBroadcasts = () => {
    return get(ref(firebaseDb, "/broadcasts")).then((snapshot) => {
      const data = snapshot.val();
      const brodcastsList: Array<any> = data ? Object.values(data) : [];

      const myBroadcasts = brodcastsList.reduce((acc: any, brodcast: any) => {
        if (brodcast[uid]) {
          return [...acc, ...Object.values(brodcast[uid])];
        }
        return acc;
      }, []);

      updateState({
        myBroadcasts,
      });
    });
  };

  return {
    broadcast: state,
    updateBroadcast: updateState,
    fetchBroadcasts,
  };
};
