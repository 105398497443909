import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthView, useAuth } from "./libs/auth";
import "./styles.css";
import { PrintView } from "./libs/print/print.view";
import { AppView } from "./libs/app/app.view";

export default function App() {
  const { auth } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/print" element={<PrintView uid={auth.user?.uid} />} />
        <Route path="*" element={<AppView />} />
      </Routes>
    </BrowserRouter>
  );
}
