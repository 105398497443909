export const clearInputAndScheduled = (clearScheduled = true) => {
  const inputBox = document.getElementById("inputBox");
  // @ts-ignore
  if (inputBox) inputBox.value = "";
  if (clearScheduled) {
    const scheduleAtInput = document.getElementById("scheduleAt");
    // @ts-ignore
    if (scheduleAtInput) scheduleAtInput.value = "";
    const scheduleTimeInput = document.getElementById("scheduleTime");
    // @ts-ignore
    if (scheduleTimeInput) scheduleTimeInput.value = "";
  }
};
