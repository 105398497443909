import { useEffect, useMemo } from "react";
import "./presets.css";
import { PresetTabs, usePresets } from "./presets.hook";
import { useChat } from "../chat/chat.hook";
import { Link, useNavigate } from "react-router-dom";
import { useModal } from "../modal";
import { useProfile } from "../profile";
import { AcceptIcon, CancelIcon, EditIcon } from "../shared";
import { clearInputAndScheduled } from "../shared/utils";

export const Presets = ({ uid, type }: any) => {
  const { profile } = useProfile(uid);
  const { chat, updateChat, addNewAppointmentMessage } = useChat(uid);
  const { updateModal } = useModal();
  const {
    presets,
    getPresets,
    updatePresets,
    addPreset,
    patchPreset,
    removePreset,
  } = usePresets(uid);
  const navigate = useNavigate();

  useEffect(() => {
    getPresets();
  }, []);

  const setNewMessage = (preset: any) => {
    if (presets.currentPreset?.id === preset.id) {
      updatePresets({
        currentPreset: null,
      });
      updateChat({
        newMessage: "",
      });
    } else if (preset.type === "sequence") {
      updatePresets({
        currentPreset: preset,
      });
      updateChat({
        newMessage: preset.name,
      });
    } else {
      updatePresets({
        currentPreset: null,
      });
      updateChat({
        newMessage: preset.value,
      });
    }
  };

  const onEditPreset = (event: any, preset: any) => {
    event.stopPropagation();
    if (preset.type === "sequence") {
      updatePresets({
        currentPreset: preset,
      });
      navigate(`/sequence`);
    } else {
      updatePresets({
        editPreset: preset.id,
      });
    }
  };

  const setNewPreset = () => {
    updatePresets({
      newPreset: true,
    });
  };

  const cancelNewPreset = () => {
    updatePresets({
      newPreset: false,
    });
  };

  const changeTab = (tab: PresetTabs) => {
    updatePresets({
      presetTab: tab,
    });
  };

  const addNewPreset = () => {
    // @ts-ignore
    const newPresetName = document.getElementById("newPresetName")?.value;
    // @ts-ignore
    const newMessage = document.getElementById("newPresetMessage")?.value;
    if (newPresetName && newMessage) {
      addPreset(newPresetName, newMessage);
      updatePresets({
        newPreset: null,
      });
    }
  };

  const savePreset = (preset: any) => {
    // @ts-ignore
    const message = document.getElementById("editingPreset")?.value;
    if (message) {
      patchPreset(preset, message).then(() => {
        updatePresets({
          editPreset: "",
        });
      });
    }
  };

  const editingPreset = (presetId: string) => {
    const { editPreset } = presets;
    return !!editPreset && editPreset === presetId;
  };

  const deletePreset = (event: any, presetId: string) => {
    event.stopPropagation();

    updateModal({
      visible: true,
      title: "Removing Preset",
      content: `Are you sure you want to remove ${presetId}?`,
      action: () => {
        removePreset(presetId).then(() => {
          getPresets();
        });
      },
    });
  };

  const { storedLimit, sequenceLimit, features } = profile;
  const { showPresets, currentPreset, presetTab, newPreset, editPreset } =
    presets;

  const presetLimit = storedLimit;

  const presetNumber =
    presets && presets.user.filter((preset: any) => !preset.type).length;
  const disabled = presetNumber >= presetLimit;

  const sequenceNumber =
    presets &&
    presets.user.filter((preset: any) => preset.type === PresetTabs.Sequence)
      .length;
  const sequenceDisabled = sequenceNumber >= sequenceLimit;

  const filteredUserPresets = useMemo(() => {
    return presets.user.filter((preset: any) => {
      if (
        presetTab === PresetTabs.Stored &&
        preset.type !== PresetTabs.Sequence
      ) {
        return true;
      }

      if (
        type !== "broadcast" &&
        presetTab === PresetTabs.Sequence &&
        preset.type === PresetTabs.Sequence
      ) {
        return true;
      }

      return false;
    });
  }, [presets.user, presetTab]);

  return (
    <div
      className={`presets${
        (showPresets || currentPreset) && !!presets && presets.default
          ? " presets-in"
          : ""
      }`}
    >
      {presetTab === PresetTabs.Stored ? (
        <>
          <p
            title={`${sequenceNumber} of ${sequenceLimit} stored messages used`}
            className="preset-count"
          >
            {presetNumber}/{presetLimit}
          </p>
          <button
            title={disabled ? "Limit reached" : ""}
            disabled={disabled}
            onClick={setNewPreset}
            className={`add-preset ${!!chat.newMessage ? "add-preset-in" : ""}`}
          >
            + Add
          </button>
        </>
      ) : (
        <>
          <p
            title={`${sequenceNumber} of ${sequenceLimit} sequence messages used`}
            className="preset-count"
          >
            {sequenceNumber}/{sequenceLimit}
          </p>
          <button
            title={sequenceDisabled ? "Limit reached" : ""}
            disabled={sequenceDisabled}
            className={"add-preset-seq"}
          >
            {!sequenceDisabled ? (
              <Link to="/sequence">+ Sequence</Link>
            ) : (
              "+ Sequence"
            )}
          </button>
        </>
      )}

      {presets && !!presets.user && (
        <button
          className={`preset-tab ${
            presetTab === PresetTabs.Stored ? "active" : ""
          }`}
          onClick={() => changeTab(PresetTabs.Stored)}
        >
          Stored
        </button>
      )}
      {type !== "broadcast" && features && features.sequence && (
        <button
          className={`preset-tab ${
            presetTab === PresetTabs.Sequence ? "active" : ""
          }`}
          onClick={() => changeTab(PresetTabs.Sequence)}
        >
          Sequence
        </button>
      )}
      <hr className="preset-tab-hr" />
      {presets && !!presets.user && (
        <div className="scrollable-vertical-box">
          {filteredUserPresets.map((preset: any, index: number) => (
            <div
              key={preset.id}
              onClick={
                preset.id === "appointment"
                  ? () => addNewAppointmentMessage(chat.currentChat)
                  : !editPreset
                  ? () => setNewMessage(preset)
                  : () => {}
              }
              className={`message-left ${
                preset.type === "sequence" ? "sequence-preset" : ""
              } ${
                (currentPreset && currentPreset.id === preset.id) ||
                chat.newMessage === preset.value
                  ? "active-preset"
                  : ""
              }`}
              title={preset.type === "sequence" ? "Sequence message" : ""}
            >
              {preset.type === "sequence" && <i className="fas fa-list-ol"></i>}
              {editingPreset(preset.id) ? (
                <textarea id="editingPreset" defaultValue={preset.value} />
              ) : preset.name ? (
                preset.name
              ) : (
                preset.value
              )}
              <button className="preset-number">{index + 1}</button>
              {preset.id !== "appointment" && !editingPreset(preset.id) && (
                <button
                  onClick={(event) => deletePreset(event, preset.id)}
                  className="delete-preset"
                >
                  <CancelIcon />
                </button>
              )}
              <button
                onClick={
                  editingPreset(preset.id)
                    ? () => savePreset(preset)
                    : (event) => onEditPreset(event, preset)
                }
                title="Edit message"
                className={
                  editingPreset(preset.id)
                    ? "edit-preset accept-preset"
                    : "edit-preset"
                }
              >
                {editingPreset(preset.id) ? <AcceptIcon /> : <EditIcon />}
              </button>
            </div>
          ))}
        </div>
      )}

      {newPreset && (
        <div className="new-preset-message-container message-left">
          <input id="newPresetName" placeholder="Preset name" />
          <textarea
            id="newPresetMessage"
            placeholder="Enter your message here"
            className="new-preset-message"
          ></textarea>
          <button onClick={cancelNewPreset} className="delete-preset">
            <CancelIcon />
          </button>
          <button
            onClick={addNewPreset}
            title="New preset"
            className="edit-preset accept-preset"
          >
            <AcceptIcon />
          </button>
        </div>
      )}
    </div>
  );
};
