export const EditIcon = () => (
  <svg x="0px" y="0px" viewBox="0 0 792 792">
    <g>
      <path
        d="M503,141c7.6,2.3,15.6,3.6,22.7,7c31.6,14.9,47.4,40.4,47.9,75c0.5,39.5,0.2,79,0.1,118.5c0,16.3-12.9,26.4-26.9,21.4
        c-8.7-3.1-13.4-10.7-13.4-21.8c0-39-0.1-78,0-117c0-9.8-1.9-18.8-7.9-26.8c-8.3-11.1-19.7-16.3-33.2-16.3
        c-81.2-0.1-162.3-0.2-243.5,0c-24,0.1-40.9,17.2-40.9,41.2c-0.1,116.5-0.1,233,0,349.4c0,24.4,17.2,41.1,41.8,41.3
        c20.8,0.1,41.7,0,62.5,0c11.9,0,20.1,6.1,21.9,16.2c1.9,10.5-3.2,18.7-14.3,23.1c-0.3,0.1-0.5,0.4-0.7,0.7c-27,0-54,0-81,0
        c-2.2-0.5-4.4-1.1-6.7-1.6c-38.6-8.8-63.8-39.9-63.9-80.3c-0.2-116.1-0.3-232.2,0-348.2c0.1-34.6,16.3-60.1,47.8-74.9
        c7.1-3.3,15.1-4.7,22.7-7C326.3,141,414.7,141,503,141z"
      />
      <path
        d="M400,653c-8.4-3.4-14.6-8.6-15.3-18.3c-0.2-2.9,0.2-6,1-8.8c7.6-25.3,15.1-50.6,23.1-75.8c1.3-4.1,3.8-8.3,6.9-11.3
        c35.9-36.2,71.9-72.2,108.2-108.1c16.8-16.6,37.2-21.8,59.8-15.1c22.8,6.8,37,22.7,41.6,46c4,20.1-1.4,38-15.6,52.9
        c-10.9,11.5-22.3,22.4-33.5,33.6c-24.6,24.6-49.3,49.2-73.9,73.8c-4.5,4.5-9.6,7.4-15.7,9.1c-25.2,6.8-50.3,13.9-75.4,20.9
        c-0.8,0.2-1.5,0.7-2.2,1C406,653,403,653,400,653z M434.3,603.9c14.1-3.9,26.8-7.3,39.6-11c1.4-0.4,2.6-1.9,3.8-3.1
        c22.5-22.4,45-44.9,67.5-67.4c1-1,1.9-2.1,2.3-2.6c-9.4-9.5-18.6-18.6-27.9-28c-0.3,0.2-1,0.7-1.5,1.3
        c-23.3,23.3-46.7,46.5-70,69.8c-0.8,0.8-1.8,1.7-2.1,2.7C442.2,578,438.4,590.3,434.3,603.9z M577,490.2c7-5.2,10.7-12.3,8.8-21.3
        c-1.7-8.4-7.3-13.7-15.8-15.3c-9-1.7-15.6,2.4-20.9,8.9C558.5,471.8,567.7,480.9,577,490.2z"
      />
      <path
        d="M371,261c34.2,0,68.3,0,102.5,0c8.9,0,16.1,5.4,18.9,13.6c2.7,8,0.3,17-6.7,21.8c-3.4,2.3-7.9,4.2-11.9,4.3
        c-69,0.3-138,0.2-207,0.1c-10.8,0-19.6-9.2-19.6-20c0-10.8,8.8-19.8,19.7-19.9C301.7,261,336.3,261,371,261z"
      />
      <path
        d="M371,341c34.2,0,68.3,0,102.5,0c8.9,0,16.1,5.4,18.9,13.6c2.7,8,0.3,17-6.7,21.8c-3.4,2.3-7.9,4.2-11.9,4.3
        c-69,0.3-138,0.2-207,0.1c-10.8,0-19.6-9.2-19.6-20c0-10.8,8.8-19.8,19.7-19.9C301.7,341,336.3,341,371,341z"
      />
      <path
        d="M330,461c-20.8,0-41.6,0.1-62.4,0c-10.9,0-18.8-7.4-20.1-18.3c-1.1-9.4,5.6-18.7,15.3-21.1c1.4-0.4,3-0.5,4.4-0.5
        c41.8,0,83.6-0.1,125.4,0c10.5,0,18.4,7.4,19.8,18.1c1.2,9.4-5.3,18.8-15.1,21.2c-2.2,0.6-4.6,0.6-6.9,0.6
        C370.3,461,350.2,461,330,461z"
      />
    </g>
  </svg>
);
