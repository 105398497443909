import { get, ref, remove, set } from "firebase/database";
import { SEGMENT_LENGTH, getSegmentCount } from "../shared/utils";
import { replaceVariables } from "../shared/utils/text-utils";
import { globalAppState, namedStore } from "../store";
import { firebaseDb } from "../firebase/firebase";
import { clearInputAndScheduled } from "../shared/utils/ui-utils";
import { v4 } from "uuid";
import moment from "moment-timezone";

export const scheduleSingle = async (newMessage: string) => {
  const { user } = globalAppState.auth;
  const { scheduleAt, scheduleTime, currentChat, chats } = globalAppState.chat;
  const { phone, sent, limit, timeZone } = globalAppState.profile;

  const segmentCount = getSegmentCount(newMessage);

  if (sent + segmentCount < limit) {
    const createdAt = new Date().getTime();
    const broadcastAt = moment
      .tz(
        `${scheduleAt} ${scheduleTime ?? ""}`,
        scheduleTime ? "YYYY-MM-DD hh:mm a" : "YYYY-MM-DD",
        timeZone
      )
      .toDate()
      .getTime();
    let sentCountUpdate = 0;

    const broadcastMsgId = `${createdAt}-${phone}-${currentChat}`
      .split("+")
      .join("");
    const convoValues = chats[currentChat];
    const newRef = `/broadcasts/${broadcastAt}/${user.uid}/${broadcastMsgId}`;
    const message = replaceVariables(convoValues, newMessage);

    sentCountUpdate =
      message.length > SEGMENT_LENGTH
        ? sentCountUpdate + getSegmentCount(message)
        : sentCountUpdate + 1;

    const prepMsg = {
      id: broadcastMsgId,
      ref: newRef,
      createdAt,
      scheduledAt: broadcastAt,
      from: phone,
      to: currentChat,
      status: "pending",
      message,
      type: "schedule",
    };

    await set(ref(firebaseDb, newRef), prepMsg)
      .then(() => {
        setTimeout(() => {
          const { phone, sent, cycle } = globalAppState.profile;

          const newSentCount = sent + sentCountUpdate;
          namedStore["profile"].updateState({
            sent: newSentCount,
          });

          set(
            ref(firebaseDb, `/messageStats/${user.uid}/sent/${cycle}`),
            newSentCount
          ).catch((err) => console.error(err));

          namedStore["chat"].updateState({
            loading: false,
            newMessage: "",
            scheduleAt: "",
            scheduleTIme: "",
          });

          clearInputAndScheduled();
        }, 2000);
      })
      .catch((err) => {
        console.error("SCHEDULE ERROR: ", err);
        setTimeout(() => {
          alert("Failed to setup the scheduled message");
        }, 2000);
      });
  } else {
    alert("You don't have enough available messages to proceed.");
  }
};

export const scheduleSequence = async (sequence: any) => {
  const { scheduleAt, scheduleTime, currentChat, chats } = globalAppState.chat;
  const { phone, sent, limit, timeZone } = globalAppState.profile;

  const createdAt = new Date().getTime();
  const scheduleDate = new Date();

  let sentCountUpdate = 0;
  const groupId = v4();

  const messagesToProcess = sequence.map((item: any, index: number) => {
    const offsetDate = scheduleAt
      ? moment
          .tz(
            `${scheduleAt} ${scheduleTime ?? ""}`,
            scheduleTime ? "YYYY-MM-DD hh:mm a" : "YYYY-MM-DD",
            timeZone
          )
          .toDate()
      : new Date(scheduleDate);

    if (item.offset) offsetDate.setDate(offsetDate.getDate() + item.offset);
    if (item.offsetMinutes)
      offsetDate.setMinutes(offsetDate.getMinutes() + item.offsetMinutes);
    const broadcastMsgId = `${createdAt}-${index}-${phone}-${currentChat}`
      .split("+")
      .join("");
    const broadcastAt = item.date
      ? moment.tz(item.date, timeZone).toDate().getTime()
      : offsetDate.getTime();
    const convoValues = chats[currentChat];
    const newRef = `/broadcasts/${broadcastAt}/${globalAppState.auth.user.uid}/${broadcastMsgId}`;
    const message = replaceVariables(convoValues, item.message);
    sentCountUpdate =
      message.length > SEGMENT_LENGTH
        ? sentCountUpdate + getSegmentCount(message)
        : sentCountUpdate + 1;

    return {
      id: broadcastMsgId,
      groupId,
      ref: newRef,
      createdAt,
      scheduledAt: broadcastAt,
      from: phone,
      to: currentChat,
      status: "pending",
      message,
      type: "schedule",
    };
  });

  if (sent + sentCountUpdate < limit) {
    namedStore["broadcast"].updateState({
      broadcasting: "Sequence setup in progress. Do not close the browser.",
    });

    await Promise.all(
      messagesToProcess.map((msg: any) => {
        return set(ref(firebaseDb, msg.ref), msg).catch((err) =>
          console.warn(`Failed saving sequence message (${msg}): `, err)
        );
      })
    ).then(() => {
      const { scheduleAt } = globalAppState.chat;
      const { sent, cycle } = globalAppState.profile;
      const newSentCount = sent + sentCountUpdate;

      namedStore["profile"].updateState({
        sent: newSentCount,
      });

      set(
        ref(
          firebaseDb,
          `/messageStats/${globalAppState.auth.user.uid}/sent/${cycle}`
        ),
        newSentCount
      ).catch((err) => console.error(err));

      namedStore["chat"].updateState({
        newMessage: "",
      });

      namedStore["presets"].updateState({
        currentPreset: null,
      });

      namedStore["broadcast"].updateState({
        broadcasting: "",
      });

      if (scheduleAt) {
        namedStore["chat"].updateState({
          scheduleAt: "",
          scheduleTIme: "",
        });
      }
    });
  } else {
    alert(
      "The Sequence is over your messaging limit. Please make sure you have enough messages to proceed."
    );
  }
};

export const removeSequenceMsg = (sqns: any) => {
  const { user } = globalAppState.auth;
  const { sent, cycle } = globalAppState.profile;

  const newSentCount = sent - getSegmentCount(sqns.message);
  set(
    ref(firebaseDb, `/messageStats/${user.uid}/sent/${cycle}`),
    newSentCount
  ).then(() => {
    namedStore["profile"].updateState({
      sent: newSentCount,
    });
  });

  return remove(ref(firebaseDb, sqns.ref));
};

export const removeScheduledByClientPhone = async (
  uid: string,
  clientPhone: string
) => {
  const snapshot = await get(ref(firebaseDb, "/broadcasts"));
  const allBroadcastDates = Object.values(snapshot.val());

  let userScheduledMessages: any[] = [];
  allBroadcastDates.forEach((entriesByDate: any) => {
    Object.entries(entriesByDate).forEach(([key, messageMap]) => {
      if (key === uid) {
        userScheduledMessages.push(...Object.values(messageMap as any));
      }
    });
  });

  const clientScheduledMessages = userScheduledMessages.filter(
    (item) => item.to === clientPhone
  );

  try {
    Promise.all(
      clientScheduledMessages.map((message) => {
        return remove(ref(firebaseDb, message.ref));
      })
    );
  } catch (ex) {
    console.error(ex);
    alert(`Failed to remove scheduled messages for ${clientPhone}`);
  }
};
