import { useMemo, useState } from "react";
import { useChat } from "../chat/chat.hook";
import { useNavigate, useParams } from "react-router-dom";
import "./contact.css";
import { useProfile } from "../profile";
import { makeOptions } from "./make-options";
import Spinner from "../../assets/spinner.svg";
import { useModal } from "../modal";

export const ContactView = ({ uid }: any) => {
  let { phone } = useParams();
  const navigate = useNavigate();

  const { profile } = useProfile(uid);
  const { chat, loading, removeNumber, patchNumber, addNewNumber } =
    useChat(uid);
  const { updateModal } = useModal();
  const currentChat = useMemo(() => chat.chats?.[phone || ""], [chat, phone]);

  const [contact, setContact] = useState(
    currentChat || {
      firstName: "",
      lastName: "",
      phone: "",
      make: "",
      tags: "",
    }
  );
  const [error, setError] = useState(null);

  const isNew = !currentChat;
  const tags = currentChat && currentChat.tags;
  const conversationsLength = chat.chats ? Object.keys(chat.chats).length : 0;
  const contactsLimit = profile && profile.contactsLimit;
  const tagsLimit = profile && profile.tagsLimit;

  const goBack = () => {
    navigate(-1);
  };

  const fieldChange = (event: any) => {
    if (error) setError(null);
    let currentValue = event.target.value;

    if (event.target.name === "phone") {
      if (currentValue[0] && currentValue[0] !== "+") {
        currentValue = `+1${currentValue}`;
      }

      currentValue = currentValue.split("-").join("");
      currentValue = currentValue.split(" ").join("");
      currentValue = currentValue.split(".").join("");
      currentValue = currentValue.split("(").join("");
      currentValue = currentValue.split(")").join("");

      setContact({
        ...contact,
        [event.target.name]: currentValue,
      });

      if (currentValue !== event.target.value) {
        event.target.value = currentValue;
      }
    } else {
      if (event.target.name === "tags") {
        const tagsLimit = profile && profile.tagsLimit;
        const tags = currentValue.split(",");
        if (tags.length >= tagsLimit) {
          currentValue = tags.slice(0, tagsLimit).join(",");
          event.target.value = currentValue;
        }
      }
      setContact({
        ...contact,
        [event.target.name]: currentValue,
      });
    }
  };

  const remove = (phone?: string) => {
    if (!phone) return;

    updateModal({
      visible: true,
      title: "Removing Contact",
      content: `Are you sure you want to remove ${phone}?`,
      action: () => {
        removeNumber(phone).then(() => {
          navigate("/");
        });
      },
    });
  };

  const save = (phone?: string) => {
    const { firstName, lastName, make, tags } = contact;

    if (phone || contact.phone) {
      if (currentChat) {
        patchNumber(
          phone || contact.phone,
          firstName,
          lastName,
          make,
          tags
        ).then(() => navigate("/"));
      } else if (contact.phone) {
        addNewNumber(contact.phone, firstName, lastName, make, tags).then(() =>
          navigate("/")
        );
      }
    }
  };

  return (
    <div className="grid">
      <form
        onSubmit={(event) => event.preventDefault()}
        className="edit-screen"
      >
        <h2>
          {currentChat ? (
            <span>
              <button onClick={goBack} className="button back-button">
                {"<"} Back
              </button>{" "}
              Edit Contact
            </span>
          ) : (
            "Add contact"
          )}
        </h2>
        {error && <p className="error">{error}</p>}
        <div className="inline-fields">
          <p>
            <input
              defaultValue={currentChat && phone}
              onChange={fieldChange}
              name="phone"
              className="input"
              placeholder="Phone number (ex: +17165015006)"
              disabled={currentChat ? true : false}
            />
            <br />
            <select
              defaultValue={currentChat && currentChat.make}
              onChange={fieldChange}
              name="make"
              className="input"
            >
              <option value="">Select make (optional)</option>
              {makeOptions.map((option) => (
                <option
                  key={option}
                  value={option}
                  // selected={(currentChat && currentChat.make) === option}
                >
                  {option}
                </option>
              ))}
            </select>
          </p>
          <p>
            <input
              defaultValue={currentChat && currentChat.firstName}
              onChange={fieldChange}
              name="firstName"
              className="input"
              placeholder="First name"
            />
            <br />
            <input
              defaultValue={currentChat && currentChat.lastName}
              onChange={fieldChange}
              name="lastName"
              className="input"
              placeholder="Last name"
            />
          </p>
          <p>
            <input
              defaultValue={tags}
              onChange={fieldChange}
              name="tags"
              className="input"
              placeholder={`Tags, up to ${tagsLimit ?? 0}, (comma separated)`}
              disabled={!tagsLimit}
            />
          </p>
        </div>
        <p className="spaced-buttons">
          {currentChat && (
            <button onClick={() => remove(phone)} className="button remove-btn">
              {!loading && "Remove"}
              {loading && <img alt="Loading..." src={Spinner} />}
            </button>
          )}
          <button
            disabled={isNew && conversationsLength >= contactsLimit}
            title={
              isNew && conversationsLength >= contactsLimit
                ? "Limit reached"
                : ""
            }
            onClick={() => save(phone)}
            className="button button-large"
          >
            {!loading && (currentChat ? "Save" : "Add")}
            {loading && <img alt="Loading..." src={Spinner} />}
          </button>
        </p>
      </form>
    </div>
  );
};
