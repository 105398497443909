import { NavLink } from "react-router-dom";
import LogoImage from "../../../assets/gomo-logo.png";
import { formatNumber, checkIfAdmin } from "../utils";
import "./top-nav.css";
import { PROFILE_KEY, useAuth } from "../../auth";
import { CancelIcon } from "../icons";

const profileOverride = sessionStorage.getItem(PROFILE_KEY);

export const TopNav = ({ isPublic, profile }: any) => {
  const { auth, processLogout } = useAuth();
  const isAdmin = checkIfAdmin(auth.user?.uid);

  return isPublic ? (
    <>
      <nav className="top-nav">
        <img className="app-logo" src={LogoImage} />
      </nav>
      <nav className="main-nav">
        <div className="nav-link">
          <NavLink to="/">Login</NavLink>
        </div>
      </nav>
    </>
  ) : (
    <>
      <nav className="top-nav">
        <img className="app-logo" src={LogoImage} />
        <div onClick={processLogout} className="logout-link">
          Logout
        </div>
        {profile && (
          <div className="nav-link message-count">
            <div>
              Your text message number is:{" "}
              <strong>
                {profile.phone ? formatNumber(profile.phone) : ""}
              </strong>
            </div>
            <div>
              Messages Sent
              <strong>
                {profile.sent} / {profile.limit}
              </strong>
            </div>
          </div>
        )}
      </nav>
      <nav className="main-nav">
        <div className="nav-link">
          <NavLink to="/">Messaging</NavLink>
        </div>
        {profile && (
          <>
            {(isAdmin || profileOverride || profile.features.broadcast) && (
              <div className="nav-link">
                <NavLink to="/broadcast">Broadcast</NavLink>
              </div>
            )}
            {(isAdmin ||
              profile.features.broadcast ||
              profile.features.sequence) && (
              <div className="nav-link">
                <NavLink to="/status">Status</NavLink>
              </div>
            )}
            <div className="nav-link">
              <NavLink to="/add">Add contact</NavLink>
            </div>
            {isAdmin && (
              <div className="nav-link">
                <NavLink to="/admin">Admin</NavLink>
              </div>
            )}
            {isAdmin && (
              <div className="nav-link">
                <NavLink to="/users">Add user</NavLink>
              </div>
            )}
          </>
        )}
        {profileOverride && (
          <button
            onClick={() => {
              sessionStorage.removeItem(PROFILE_KEY);
              window.location.reload();
            }}
            className="exit-btn"
          >
            <CancelIcon /> Exit Profile
          </button>
        )}
      </nav>
    </>
  );
};
